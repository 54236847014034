/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Row, Spin, Col, Card, Tooltip } from 'antd';
import moment, { Moment } from 'moment';
import { useNavigate } from 'react-router-dom';
import DashBoardActions from '../../../../redux/middleware/dashboard';
import ReportsApi from '../../../../redux/middleware/reports';
import Utils from '../../../../redux/utils';
import '../../style.scss';
import ChartBarHorizontal from '../../../../components/charts/barHorizontal';
import ChartLine from '../../../../components/charts/line';
import ChartBarVertical from '../../../../components/charts/barVertical';
import StackedBarHorizontal from '../../../../components/charts/stackedBarHorizontal';
import ChartPie from '../../../../components/charts/pie';
import Eye from '../../../../assets/images/Eye.svg';
import ArrowUp from '../../../../assets/images/UpArrow.png';
import ArrowDown from '../../../../assets/images/DownArrowGreen.png';
import {
  handleFloat,
  handleFloatForSpecialCase,
} from '../../../../helper/sortData';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import menuCategoryWizard from '../../../menuCategoryWizard/index';
interface dashboardResponse {
  currency: string;
  sales: any;
  cogs: any;
  stockOnHand: any;
  variance: any;
  menu: any;
  purchasing: any;
  inventory: any;
  branches: number;
  brands: number;
  target: any;
  wastage: any;
  total_orders: any;
  stockDistribution: any;
  wastage_by_type: any[];
  varianceBreakup: any;
  revenueBreakup: any;
  categoryWisePurchasing: any;
  categoryPerformance: any;
  categoryWiseRevenue: any;
  dayWiseRevenue: any;
  categoryWiseWastage: any;
  brandWastage: any;
  branchWastage: any;
  profibility: any;
  dayWiseProfibility: any;
  dayWiseWastage: any;
}
interface BranchViewProps {
  start_date?: Moment;
  end_date?: Moment;
  activeTab?: any;
  brand_count?: number;
  selectedBrands?: any;
  selectedBranches?: any;
}
interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

type DataObject = { [key: string]: any };
type DataArray = DataObject[];
type Data = DataArray | DataObject | number | null | undefined;

const BranchView: React.FC<BranchViewProps> = ({
  start_date,
  end_date,
  activeTab,
  brand_count,
  selectedBrands,
  selectedBranches,
}) => {
  const navigate = useNavigate();
  const user = Utils.getCurrentUser();
  const [info, setInfo] = React.useState({} as dashboardResponse);
  const [categoryPerformance, setCategoryPerformance] = useState<any>([]);
  const [categoryWiseRevenue, setCategoryWiseRevenue] = useState<any>([]);
  const [profibility, setProfibility] = useState<any>([]);
  const [stockDistribution, setStockDistribution] = useState<any>([]);
  const [brandWastage, setBrandWastage] = useState([]);
  const [branchWastage, setBranchWastage] = useState([]);
  const [dayWiseWastage, setDayWiseWastage] = useState<any>([]);
  const [Loader, setLoader] = useState(false);
  const startDate = start_date ?? moment();
  const endDate = end_date ?? moment();
  const tag =
    activeTab === '2' ? 'brand' : activeTab === '3' ? 'shop' : 'brand';

  const replaceNegativeValues = (data: Data, limit?: number): Data => {
    if (Array.isArray(data)) {
      const processedData = data.map((item) => replaceNegativeValues(item));
      return limit ? processedData.slice(0, limit) : processedData;
    } else if (typeof data === 'object' && data !== null) {
      return Object.fromEntries(
        Object.entries(data).map(([key, value]) => [
          key,
          replaceNegativeValues(value),
        ])
      );
    } else if (typeof data === 'number') {
      return data < 0 ? 0 : data;
    } else {
      return data;
    }
  };

  const fetchData = async () => {
    setLoader(true);
    const response: any = await DashBoardActions.fetchDashboardInfo(
      selectedBrands,
      selectedBranches,
      startDate?.format('YYYY-MM-DD'),
      endDate?.format('YYYY-MM-DD'),
      'date',
      tag
    );
    const { success } = response;
    if (success) {
      const data: dashboardResponse = response?.data;
      setInfo(data);
      // console.log('brand_count', brand_count);
      setStockDistribution(
        replaceNegativeValues(data?.stockDistribution?.data || [], 5)
      );
      setDayWiseWastage(
        replaceNegativeValues(data?.dayWiseWastage?.data || [], 7)
      );
      if (user?.roles[0]?.id === 3) {
        setProfibility(
          replaceNegativeValues(data?.dayWiseRevenue?.data || [], 7)
        );
      } else {
        if (brand_count && brand_count > 1) {
          setProfibility(
            replaceNegativeValues(data?.profibility?.data || [], 7)
          );
        } else {
          setProfibility(
            replaceNegativeValues(data?.dayWiseProfibility?.data || [], 7)
          );
        }
      }
      if (tag === 'shop') {
        setCategoryPerformance(
          replaceNegativeValues(data?.categoryWisePurchasing?.data || [], 5)
        );
      } else {
        if (brand_count && brand_count > 1) {
          setCategoryPerformance(
            replaceNegativeValues(data?.varianceBreakup?.data || [], 5)
          );
        } else {
          setCategoryPerformance(
            replaceNegativeValues(data?.categoryPerformance?.data || [], 5)
          );
        }
      }
      if (brand_count && brand_count > 1) {
        setCategoryWiseRevenue(data?.revenueBreakup?.data);
      } else {
        setCategoryWiseRevenue(
          replaceNegativeValues(data?.categoryWiseRevenue?.data || [], 5)
        );
      }
      if (tag === 'brand') {
        if (brand_count && brand_count > 1) {
          setBrandWastage(data?.brandWastage);
        } else {
          setBrandWastage(data?.categoryWiseWastage);
        }
      } else {
        if (brand_count && brand_count > 1) {
          setBranchWastage(data?.branchWastage);
        } else {
          setBranchWastage(data?.categoryWiseWastage);
        }
      }
    }
    setLoader(false);
  };

  // const handleReportFilter = async (filter: any) => {
  //   setLoader(true);
  //   // console.log(tabPills, selectedCategory, tabsValue);
  //   const response = await ReportsApi.getReports(
  //     selectedBrands,
  //     selectedBranches,
  //     tag,
  //     filter,
  //     1000,
  //     'date',
  //     start_date?.format('YYYY-MM-DD'),
  //     end_date?.format('YYYY-MM-DD')
  //   );
  //   setLoader(false);
  // };

  React.useEffect(() => {
    if (selectedBrands && selectedBranches) {
      fetchData();
    }
  }, [startDate, endDate, tag, selectedBrands, selectedBranches]);

  const Menucolumns1: TableColumnsType<DataType> = [
    {
      title: <div style={{ textAlign: 'center' }}>Item</div>,
      dataIndex: 'name',
      width: 150,
    },
    {
      title: <div style={{ textAlign: 'center' }}>Brand</div>,
      dataIndex: 'brand_name',
      width: 150,
    },
    {
      title: <div style={{ textAlign: 'center' }}>Sales Value</div>,
      dataIndex: 'value',
      width: 150,
    },
  ];

  const Menucolumns2: TableColumnsType<DataType> = [
    {
      title: <div style={{ textAlign: 'center' }}>Brand (Inactive Menu)</div>,
      dataIndex: 'brand_name',
      width: 150,
    },
    {
      title: <div style={{ textAlign: 'center' }}>Item</div>,
      dataIndex: 'name',
      width: 150,
    },
  ];

  const Inventorycolumns1 = [
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          Ingredients (Without Suppliers)
        </div>
      ),
      dataIndex: 'ingredient_name',
      width: 150,
    },
    {
      title: <div style={{ textAlign: 'center' }}>Ingredient Type</div>,
      dataIndex: 'ingredient_type',
      width: 150,
    },
  ];

  const Inventorycolumns2: TableColumnsType<DataType> = [
    {
      title: (
        <div style={{ textAlign: 'center' }}>Category (Most Used Items)</div>
      ),
      dataIndex: 'category_name',
      width: 150,
    },
    {
      title: <div style={{ textAlign: 'center' }}>Stock</div>,
      dataIndex: 'stock',
      width: 150,
    },
  ];

  const Purchasingcolumns1: TableColumnsType<DataType> = [
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          {tag === 'shop'
            ? 'Branch (Pending Purchase Orders)'
            : 'Brand (Pending Purchase Orders)'}
        </div>
      ),
      dataIndex: tag === 'shop' ? 'shop_name' : 'brand_name',
      width: 150,
    },
    {
      title: <div style={{ textAlign: 'center' }}>Total Cost</div>,
      dataIndex: 'total_cost',
      width: 150,
    },
  ];

  const Purchasingcolumns2: TableColumnsType<DataType> = [
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          {tag === 'shop'
            ? 'Branch (Pending Transfers)'
            : 'Brand (Pending Transfers)'}
        </div>
      ),
      dataIndex: tag === 'shop' ? 'shop_name' : 'brand_name',
      width: 150,
    },
    {
      title: <div style={{ textAlign: 'center' }}>Count</div>,
      dataIndex: 'count',
      width: 150,
    },
  ];

  function formatNumberWithEllipsis(number: any) {
    const numberStr = number.toString();
    if (numberStr.length > 9) {
      return numberStr.substring(0, 9) + '...';
    }
    return numberStr;
  }

  const hasMoreThanThreeKeys = dayWiseWastage.some((entry: any) => {
    return Object.keys(entry).length > 3;
  });

  return (
    <div className="Dashboard">
      {!Loader ? (
        <div className="dashboard-container">
          <Row>
            {/* Cards Column */}
            <Col lg={4} md={24}>
              <Card className="centered-card">
                <Tooltip
                  title={
                    <div>
                      <div className="tooltipHead">View Report</div>
                      <div className="tootipText">Sales</div>
                    </div>
                  }
                >
                  <div
                    className="eye-icon-container"
                    onClick={() => {
                      navigate('/app/reports', {
                        state: {
                          from: 'Dashboard',
                          selectedFilter: 'sales_card',
                        },
                      });
                      // handleReportFilter('sales_card');
                    }}
                  >
                    <img src={Eye} alt="View Reports" className="eye-icon" />
                  </div>
                </Tooltip>
                <div className="card-section">
                  <div className="card-title">
                    Sales
                    {info?.sales?.growth === 'positive' && (
                      <img className="sale-icon" src={ArrowUp} />
                    )}
                    {info?.sales?.growth === 'negative' && (
                      <img src={ArrowDown} />
                    )}
                  </div>
                </div>
                <div className="sales-amount">
                  <span className="small-text">{info?.currency}</span>
                  <span className="large-text">
                    {' '}
                    {handleFloat(info?.sales?.amount)}
                  </span>
                  <span className="subscript">
                    ({info?.sales?.orders} orders)
                  </span>
                </div>
              </Card>
              <Card className="centered-card">
                <Tooltip
                  title={
                    <div>
                      <div className="tooltipHead">View Report</div>
                      <div className="tootipText">COGS</div>
                    </div>
                  }
                >
                  <div
                    className="eye-icon-container"
                    onClick={() => {
                      navigate('/app/reports', {
                        state: {
                          from: 'Dashboard',
                          selectedFilter: 'cogs',
                        },
                      });
                      // handleReportFilter('cogs');
                    }}
                  >
                    <img src={Eye} alt="View Reports" className="eye-icon" />
                  </div>
                </Tooltip>
                <div className="card-section">
                  <div className="card-title uppercase">COGS</div>
                </div>
                <div className="sales-amount">
                  <span className="large-text">
                    {handleFloat(info?.cogs?.percentage)}%
                  </span>
                </div>
                <div className="sales-amount">
                  <span className="small-text">{info?.currency}</span>
                  <span className="large-text"> {info?.cogs?.amount}</span>
                </div>
              </Card>
              <Card className="centered-card">
                <Tooltip
                  title={
                    <div>
                      <div className="tooltipHead">View Report</div>
                      <div className="tootipText">Stock On Hand</div>
                    </div>
                  }
                >
                  <div
                    className="eye-icon-container"
                    onClick={() => {
                      navigate('/app/reports', {
                        state: {
                          from: 'Dashboard',
                          selectedFilter: 'stock_on_hand',
                        },
                      });
                      // handleReportFilter('stock_on_hand');
                    }}
                  >
                    <img src={Eye} alt="View Reports" className="eye-icon" />
                  </div>
                </Tooltip>
                <div className="card-section">
                  <div className="card-title">Stock On Hand</div>
                </div>
                <div className="sales-amount">
                  <span className="small-text">{info?.currency}</span>
                  <span className="large-text">
                    {handleFloat(info?.stockOnHand?.amount)}
                  </span>
                  <span className="subscript">
                    ({info?.stockOnHand?.items} items)
                  </span>
                </div>
              </Card>
              <Card className="centered-card variance">
                <Tooltip
                  title={
                    <div>
                      <div className="tooltipHead">View Report</div>
                      <div className="tootipText">Variance</div>
                    </div>
                  }
                >
                  <div
                    className="eye-icon-container"
                    onClick={() => {
                      navigate('/app/reports', {
                        state: {
                          from: 'Dashboard',
                          selectedFilter: 'variance',
                        },
                      });
                      // handleReportFilter('variance');
                    }}
                  >
                    <img src={Eye} alt="View Reports" className="eye-icon" />
                  </div>
                </Tooltip>
                <div className="card-section">
                  <div className="card-title uppercase">Variance</div>
                </div>
                <div className="sales-amount">
                  <span className="large-text">
                    {info?.variance?.percentage}%
                  </span>
                  <span className="subscript">
                    ({info?.currency} {info?.variance?.amount})
                  </span>
                </div>
                <div className="variance-bottom-section">
                  <div className="centercard">
                    <div className="itemone">Production</div>
                    <div className="item-value-one">
                      {info?.variance?.production}
                    </div>
                  </div>
                  <div className="centercard">
                    <div className="itemtwo">Storage</div>
                    <div className="item-value-two">
                      {info?.variance?.storage}
                    </div>
                  </div>
                  <div className="centercard">
                    <div className="itemthree">Restaurant</div>
                    <div className="item-value-three">
                      {info?.variance?.restaurant}
                    </div>
                  </div>
                  <div className="centercard">
                    <div className="itemfour">
                      <Tooltip
                        title={
                          <div>
                            <div className="tooltipHead">Stock Adjustment</div>
                            <div className="tootipText">
                              {info?.variance?.stockAdjustment}
                            </div>
                          </div>
                        }
                      >
                        <div>
                          {formatNumberWithEllipsis('Stock Adjustment')}
                        </div>
                      </Tooltip>
                    </div>
                    <div className="item-value-four">
                      {info?.variance?.stockAdjustment}
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            {/* Graphs Column */}
            <Col lg={20} md={24}>
              <Row>
                <Col>
                  <Card className="graph-card">
                    <Tooltip
                      title={
                        <div>
                          <div className="tooltipHead">View Report</div>
                          {brand_count && brand_count > 1 ? (
                            <div className="tootipText">Revenue Breakup</div>
                          ) : (
                            <div className="tootipText">
                              Category Wise Revenue
                            </div>
                          )}
                        </div>
                      }
                    >
                      <div
                        className="eye-icon-container"
                        onClick={() => {
                          navigate('/app/reports', {
                            state: {
                              from: 'Dashboard',
                              selectedFilter:
                                brand_count && brand_count > 1
                                  ? 'revenue_break_up'
                                  : 'category_wise_revenue',
                            },
                          });
                        }}
                      >
                        <img
                          src={Eye}
                          alt="View Reports"
                          className="eye-icon"
                        />
                      </div>
                    </Tooltip>
                    <div className="card-section">
                      {brand_count && brand_count > 1 ? (
                        <div className="card-title-graph">Revenue Breakup</div>
                      ) : (
                        <div className="card-title-graph">
                          Category Wise Revenue
                        </div>
                      )}
                    </div>
                    <ChartBarHorizontal data={categoryWiseRevenue} />
                  </Card>
                </Col>
                <Col>
                  <Card className="graph-card pie-card">
                    <Tooltip
                      title={
                        <div>
                          <div className="tooltipHead">View Report</div>
                          {brand_count && brand_count > 1 ? (
                            <div className="tootipText">
                              {tag === 'shop'
                                ? 'Branch Wastage'
                                : 'Brand Wastage'}
                            </div>
                          ) : (
                            <div className="tootipText">
                              Category Wise Wastage
                            </div>
                          )}
                        </div>
                      }
                    >
                      <div
                        className="eye-icon-container"
                        onClick={() => {
                          navigate('/app/reports', {
                            state: {
                              from: 'Dashboard',
                              selectedFilter:
                                brand_count && brand_count > 1
                                  ? tag === 'shop'
                                    ? 'branch_wastage'
                                    : tag === 'brand'
                                    ? 'brand_wastage'
                                    : 'revenue_break_up'
                                  : 'daywise_wastage',
                            },
                          });
                        }}
                      >
                        <img
                          src={Eye}
                          alt="View Reports"
                          className="eye-icon"
                        />
                      </div>
                    </Tooltip>
                    <div className="card-section">
                      {brand_count && brand_count > 1 ? (
                        <div className="card-title-graph">
                          {tag === 'shop' ? 'Branch Wastage' : 'Brand Wastage'}
                        </div>
                      ) : (
                        <div className="card-title-graph">
                          Category Wise Wastage
                        </div>
                      )}
                    </div>
                    {tag === 'shop' ? (
                      <ChartPie
                        data={branchWastage}
                        currency={info?.currency}
                      />
                    ) : (
                      <ChartPie data={brandWastage} currency={info?.currency} />
                    )}
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card className="graph-card">
                    <Tooltip
                      title={
                        <div>
                          <div className="tooltipHead">View Report</div>
                          <div className="tootipText">Stock Distribution</div>
                        </div>
                      }
                    >
                      <div
                        className="eye-icon-container"
                        onClick={() => {
                          navigate('/app/reports', {
                            state: {
                              from: 'Dashboard',
                              selectedFilter: 'stock_distribution',
                            },
                          });
                        }}
                      >
                        <img
                          src={Eye}
                          alt="View Reports"
                          className="eye-icon"
                        />
                      </div>
                    </Tooltip>
                    <div className="card-section">
                      <div className="card-title-graph">Stock Distribution</div>
                    </div>
                    <ChartBarHorizontal data={stockDistribution} />
                  </Card>
                </Col>
                <Col>
                  <Card className="graph-card">
                    <Tooltip
                      title={
                        <div>
                          <div className="tooltipHead">View Report</div>
                          {tag === 'shop' ? (
                            <div className="tootipText">Purchasing</div>
                          ) : brand_count && brand_count > 1 ? (
                            <div className="tootipText">Variance Breakup</div>
                          ) : (
                            <div className="tootipText">
                              Category Performance
                            </div>
                          )}
                        </div>
                      }
                    >
                      <div
                        className="eye-icon-container"
                        onClick={() => {
                          navigate('/app/reports', {
                            state: {
                              from: 'Dashboard',
                              selectedFilter:
                                tag === 'shop'
                                  ? 'purchasing'
                                  : brand_count && brand_count > 1
                                  ? 'variance_breakup'
                                  : 'category_performance',
                            },
                          });
                        }}
                      >
                        <img
                          src={Eye}
                          alt="View Reports"
                          className="eye-icon"
                        />
                      </div>
                    </Tooltip>
                    <div className="card-section">
                      {tag === 'shop' ? (
                        <div className="card-title-graph">Purchasing</div>
                      ) : brand_count && brand_count > 1 ? (
                        <div className="card-title-graph">Variance Breakup</div>
                      ) : (
                        <div className="card-title-graph">
                          Category Performance
                        </div>
                      )}
                    </div>
                    {tag === 'brand' && brand_count && brand_count > 1 ? (
                      <ChartBarVertical
                        data={categoryPerformance}
                        dataKey1="targetFCR"
                        dataKey2="actualFCR"
                      />
                    ) : tag === 'brand' && brand_count && brand_count === 1 ? (
                      <ChartBarVertical
                        data={categoryPerformance}
                        dataKey1="menuWeightage"
                        dataKey2="orderWeightage"
                      />
                    ) : (
                      <ChartBarVertical
                        data={categoryPerformance}
                        dataKey1="value"
                      />
                    )}
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Card className="graph-card">
                    <Tooltip
                      title={
                        <div>
                          <div className="tooltipHead">View Report</div>
                          <div className="tootipText">Day Wise Wastage</div>
                        </div>
                      }
                    >
                      <div
                        className="eye-icon-container"
                        onClick={() => {
                          navigate('/app/reports', {
                            state: {
                              from: 'Dashboard',
                              selectedFilter: 'daywise_wastage',
                            },
                          });
                        }}
                      >
                        <img
                          src={Eye}
                          alt="View Reports"
                          className="eye-icon"
                        />
                      </div>
                    </Tooltip>
                    <div className="card-section">
                      <div className="card-title-graph">Day Wise Wastage</div>
                    </div>
                    {hasMoreThanThreeKeys ? (
                      <StackedBarHorizontal
                        data={dayWiseWastage}
                        isMulti
                        source="dayWiseWastage"
                        brandcount={brand_count}
                      />
                    ) : (
                      <ChartBarHorizontal
                        data={dayWiseWastage}
                        source="dayWiseWastage"
                        brandcount={brand_count}
                      />
                    )}
                  </Card>
                </Col>
                <Col>
                  <Card className="graph-card">
                    <Tooltip
                      title={
                        <div>
                          <div className="tooltipHead">View Report</div>
                          {user?.roles[0]?.id === 3 ? (
                            <div className="tootipText">Day Wise Revenue</div>
                          ) : brand_count && brand_count > 1 ? (
                            <div className="tootipText">Profitability</div>
                          ) : (
                            <div className="tootipText">
                              Day Wise Profitability
                            </div>
                          )}
                        </div>
                      }
                    >
                      <div
                        className="eye-icon-container"
                        onClick={() => {
                          navigate('/app/reports', {
                            state: {
                              from: 'Dashboard',
                              selectedFilter:
                                user?.roles[0]?.id === 3
                                  ? 'day_wise_revenue'
                                  : brand_count && brand_count > 1
                                  ? 'profitability'
                                  : 'day_wise_profitability',
                            },
                          });
                        }}
                      >
                        <img
                          src={Eye}
                          alt="View Reports"
                          className="eye-icon"
                        />
                      </div>
                    </Tooltip>
                    <div className="card-section">
                      {user?.roles[0]?.id === 3 ? (
                        <div className="card-title-graph">Day Wise Revenue</div>
                      ) : brand_count && brand_count > 1 ? (
                        <div className="card-title-graph">Profitability</div>
                      ) : (
                        <div className="card-title-graph">
                          Day Wise Profitability
                        </div>
                      )}
                    </div>
                    {brand_count && brand_count > 1 ? (
                      <ChartLine data={profibility} />
                    ) : (
                      <ChartBarHorizontal
                        data={profibility}
                        isMulti
                        source="profibility"
                      />
                    )}
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          {user?.roles[0]?.id === 3 && (
            <>
              <Card className="dashboard-bt-card">
                <div className="card-section">
                  <div className="card-header">
                    <div className="table-card-title">Menu</div>
                    <div className="menu-count">
                      {info?.menu?.menu_items} items
                    </div>
                  </div>
                </div>
                <Row gutter={[16, 16]} style={{ paddingRight: '5px' }}>
                  <Col lg={12} md={24}>
                    <Card className="dashboard-bottom-cards">
                      <Table
                        columns={Menucolumns1}
                        dataSource={info?.menu?.category}
                        pagination={false}
                        scroll={{ y: 250 }}
                        bordered={true}
                      />
                    </Card>
                  </Col>
                  <Col lg={12} md={24}>
                    <Card className="dashboard-bottom-cards">
                      <Table
                        columns={Menucolumns2}
                        dataSource={info?.menu?.inactive_menu_items}
                        pagination={false}
                        scroll={{ y: 250 }}
                        bordered={true}
                      />
                    </Card>
                  </Col>
                </Row>
              </Card>

              <Card className="dashboard-bt-card">
                <div className="card-section">
                  <div className="card-header">
                    <div className="table-card-title">Inventory</div>
                    <div className="menu-count">
                      {info?.inventory?.stockOnHand?.count} items (
                      {info?.inventory?.stockOnHand?.value})
                    </div>
                  </div>
                </div>
                <Row gutter={[16, 16]} style={{ paddingRight: '5px' }}>
                  <Col lg={12} md={24}>
                    <Card className="dashboard-bottom-cards">
                      <Table
                        columns={Inventorycolumns1}
                        dataSource={info?.inventory?.itemsWithoutSupplier}
                        pagination={false}
                        scroll={{ y: 250 }}
                        bordered={true}
                      />
                    </Card>
                  </Col>
                  <Col lg={12} md={24}>
                    <Card className="dashboard-bottom-cards">
                      <Table
                        columns={Inventorycolumns2}
                        dataSource={info?.inventory?.mostUsedItems}
                        pagination={false}
                        scroll={{ y: 250 }}
                        bordered={true}
                      />
                    </Card>
                  </Col>
                </Row>
              </Card>

              <Card className="dashboard-bt-card">
                <div className="card-section">
                  <div className="card-header">
                    <div className="table-card-title">Purchasing</div>
                    <div className="menu-count">
                      {info?.purchasing?.supplyCount} items
                    </div>
                  </div>
                </div>
                <Row gutter={[16, 16]} style={{ paddingRight: '5px' }}>
                  <Col lg={12} md={24}>
                    <Card className="dashboard-bottom-cards">
                      <Table
                        columns={Purchasingcolumns1}
                        dataSource={info?.purchasing?.pendingPurchaseOrders}
                        pagination={false}
                        scroll={{ y: 250 }}
                        bordered={true}
                      />
                    </Card>
                  </Col>
                  <Col lg={12} md={24}>
                    <Card className="dashboard-bottom-cards">
                      <Table
                        columns={Purchasingcolumns2}
                        dataSource={info?.purchasing?.pendingTransfers}
                        pagination={false}
                        scroll={{ y: 250 }}
                        bordered={true}
                      />
                    </Card>
                  </Col>
                </Row>
              </Card>
            </>
          )}
        </div>
      ) : (
        <div className="loader-wrapper">
          <Spin spinning={Loader} className="Loader" />
        </div>
      )}
    </div>
  );
};

export default BranchView;
