/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Col, Modal, Row, Spin } from 'antd';
import SubscriptionApi from '../../../redux/middleware/subscription';
import PlanCard from './PlanCard';
import StripeProvider from './paymentProvider';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import './styles.scss';
import Utils from '../../../redux/utils';
import LeadGenerationApi from '../../../redux/middleware/leadGeneration';

type Props = {
  id: number | string;
  onSetupSuccess: any;
  disable?: boolean;
};

const PaymentForm = ({ handleContinue }: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<any>(null);

  const onProceedPayment = async () => {
    if (!stripe || !elements) {
      return;
    }

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // No return_url needed
      },
      redirect: 'if_required', // This will prevent automatic redirects
    });

    if (error) {
      setError(error);
      // console.error('Payment failed:', error);
    } else if (setupIntent && setupIntent.status === 'succeeded') {
      // console.log('Payment successful:', paymentIntent);
      const { payment_method } = setupIntent;
      handleContinue(payment_method);
    }
  };

  return (
    <div>
      <div className="paymentCardContainer">
        <PaymentElement />
      </div>
      {error && <div className="error">{error.message}</div>}
      <div className="butonGroupDialog">
        <div className="continue wizard-button" onClick={onProceedPayment}>
          Proceed
        </div>
      </div>
    </div>
  );
};

const Plans = (props: Props) => {
  const { id, onSetupSuccess, disable } = props;
  const [planData, setPlanData] = useState<any>([]);
  const [current] = useState(1);
  const [dataLength] = useState(5000);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [subscriptionData, setSubscriptionData] = useState<any>();
  const [selectedPlanStatus, setSelectedPlanStatus] = useState<any>();
  const [trialPeriod, setTrialPeriod] = useState<any>();
  const [apiLoader, setApiLoader] = useState(false);
  const [isUpdatePlan, setUpdatePlan] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getPackageListing();
    getSubscriptionInfo();
  }, []);

  const getSubscriptionInfo = async () => {
    const response = await SubscriptionApi.getSubscriptionById(id);
    if (response?.success) {
      const { data } = response;
      setSubscriptionData(data);
      setSelectedPlanStatus(data?.status);
    }
  };

  React.useEffect(() => {
    if (planData?.length) {
      let matchedPlan = null;
      if (subscriptionData?.plan_id) {
        matchedPlan = planData.find(
          (plan: any) => plan?.id === subscriptionData?.plan_id
        );
      }
      if (
        selectedPlanStatus &&
        selectedPlanStatus === 'trialing' &&
        matchedPlan
      ) {
        setSelectedPlan(matchedPlan);
      } else {
        setSelectedPlan(planData[1]);
      }
    }
  }, [planData, subscriptionData, selectedPlanStatus]);

  const getPackageListing = async () => {
    setApiLoader(true);
    const response = await SubscriptionApi.getSubscriptionPackages(
      dataLength,
      current
    );
    if (response?.success) {
      const { data } = response;
      if (data) {
        const { month } = data;
        setPlanData(month);
        setTrialPeriod(month[0]?.trial_period_days);
      }
    }
    setApiLoader(false);
  };

  const openSubscriptionPaymentModal = () => {
    setModalOpen(true);
  };

  const handleContinue = async (key = '') => {
    if (selectedPlan?.product?.name === 'Enterprise') {
      try {
        setApiLoader(true);

        const user = await Utils.getCurrentUser();
        const company = await Utils.getCurrentCompany();
        const brand = await Utils.getCurrentBrand();
        const shop = await Utils.getCurrentShop();

        const payload = {
          company_id: company?.id,
          brand_id: brand?.brand_id || 0,
          shop_id: shop?.id || 0,
          created_by: user?.id,
          screen_id: 1,
          description: 'Enterprise Subscription',
        };
        const response = await LeadGenerationApi.createLead(payload);
        setApiLoader(false);
        if (response?.success) {
          onSetupSuccess('3');
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      const payload = {
        user_id: id,
        price_id: selectedPlan?.id,
        subscription_payment_type_id: 1,
        free_trial_days: selectedPlanStatus === 'canceled' ? 0 : trialPeriod, // selectedPackage?.trial,
        quantity: 1,
        payment_method: key,
        // starts_at: moment().utc().format('YYYY-MM-DD hh:mm:ss'), // '2023-08-25 13:00:00'
      };
      try {
        setApiLoader(true);
        let response;
        response = await SubscriptionApi.manualSubscription(payload);
        setApiLoader(false);
        if (response?.success) {
          setModalOpen(false);
          onSetupSuccess('3');
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleNext = () => onSetupSuccess('3');

  return (
    <Spin
      size="large"
      className="loadIngredient recipeBuilderloaderHeight"
      spinning={apiLoader}
    >
      <div className="pricingPlanContainer">
        <div style={{ padding: '15px' }}>
          <Row className={`PricePlan ${disable ? 'disabled-section' : ''}`}>
            {planData
              // .sort((a: any, b: any) => a?.amount - b?.amount)
              .map((val: any) => {
                return (
                  <Col key={val?.id} xl={7} lg={12} md={24}>
                    <PlanCard
                      CardData={val}
                      onPressItem={setSelectedPlan}
                      selectedPlan={selectedPlan}
                      subscriptionStatus={selectedPlanStatus}
                    />
                  </Col>
                );
              })}
          </Row>

          <Modal
            title={<div className="title font">Subscription Payment</div>}
            centered
            open={modalOpen}
            onCancel={() => setModalOpen(false)}
            width={480}
            footer={null}
          >
            <StripeProvider selectedPlan={selectedPlan}>
              <PaymentForm handleContinue={handleContinue} />
            </StripeProvider>
          </Modal>

          <div className="butonGroup" style={{ width: '100%' }}>
            {!isUpdatePlan && (
              <div
                className="continue wizard-button"
                onClick={() => {
                  if (selectedPlanStatus !== 'trialing') {
                    {
                      selectedPlan &&
                      selectedPlan?.product?.name !== 'Enterprise'
                        ? openSubscriptionPaymentModal()
                        : handleContinue();
                    }
                  } else {
                    onSetupSuccess('3');
                  }
                }}
              >
                {selectedPlan && selectedPlan?.product?.name === 'Enterprise'
                  ? 'Save changes'
                  : 'Get Subscription'}
              </div>
            )}
            {isUpdatePlan && (
              <div className="continue wizard-button" onClick={handleNext}>
                Next
              </div>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default Plans;
