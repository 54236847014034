/* eslint-disable no-unused-vars */
import {
  BarChart,
  Rectangle,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

interface ChartBarHorizontalProps {
  isMulti?: boolean;
  data: any[];
  source?: any;
  brandcount?: number;
}

const ChartBarHorizontal = (props: ChartBarHorizontalProps) => {
  const { isMulti = false, data, source, brandcount } = props;

  function formatNumberWithEllipsis(number: any) {
    const numberStr = number.toString();
    if (numberStr.length > 3) {
      return numberStr.substring(0, 3) + '...';
    }
    return numberStr;
  }

  const chartWidth =
    (source === 'dayWiseWastage' || source === 'profibility') &&
    brandcount &&
    brandcount > 1
      ? 960
      : (source === 'dayWiseWastage' || source === 'profibility') &&
        brandcount &&
        brandcount === 1
      ? 460
      : 460;

  if (!data || data.length === 0) {
    return <div className="no-data-graph">No data to display</div>;
  }

  const yAxisDataKey = isMulti ? undefined : 'value';
  const bars = [];

  if (source === 'dayWiseWastage' || source === 'profibility') {
    const keys = Object.keys(data[0]).filter((key) => key.startsWith('value'));

    bars.push(
      ...keys.map((key, index) => {
        const barColor = ['#d62728', '#a5a5a5', '#f9ab01'][index % 3];
        return (
          <Bar
            key={key}
            dataKey={key}
            fill={barColor}
            activeBar={<Rectangle fill={barColor} stroke="#a5a5a5" />}
          />
        );
      })
    );
  } else if (isMulti) {
    const keys = Object.keys(data[0]).filter((key) => key.startsWith('value'));

    bars.push(
      ...keys.map((key, index) => {
        const barColor = ['#d62728', '#a5a5a5', '#f9ab01'][index % 3];
        return (
          <Bar
            key={key}
            dataKey={key}
            fill={barColor}
            activeBar={<Rectangle fill={barColor} stroke="#a5a5a5" />}
          />
        );
      })
    );
  } else {
    bars.push(
      <Bar
        key="value"
        dataKey="value"
        fill="#d62728"
        activeBar={<Rectangle fill="#d62728" stroke="#a5a5a5" />}
      />
    );
  }

  return data && data.length > 0 ? (
    <BarChart
      width={chartWidth}
      height={180}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" tickFormatter={formatNumberWithEllipsis} />
      <YAxis />
      <Tooltip
        formatter={(value, name, entry) => {
          if (
            source === 'dayWiseWastage' ||
            source === 'profibility' ||
            isMulti
          ) {
            let labelKey = '';
            try {
              labelKey = name.toString().replace('value', 'label');
            } catch (e) {
              console.error('Error replacing text:', e);
            }
            return [`${value}`, entry.payload[labelKey]];
          }
          return [`${value}`, entry.payload.label];
        }}
      />
      <Legend />
      {bars}
    </BarChart>
  ) : (
    <div className="no-data-graph">No data to display</div>
  );
};

export default ChartBarHorizontal;
