/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  PaginationProps,
  Spin,
  Dropdown,
  Space,
  Tooltip,
} from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  Pagination,
  Breadcrumb,
  ListCount,
  SearchBox,
  ListViewWithoutExpand,
  FeedbackView,
} from '../../components';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import FeedbackApi from '../../redux/middleware/feedback';
import IngredientActions from '../../redux/middleware/ingredient';
import { sortNumber, sortedData } from '../../helper/sortData';
import Clock from '../../assets/images/Clock.png';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import More from '../../assets/images/More.png';
import Eye from '../../assets/images/Eye.svg';
import moment from 'moment';
import './feedback.scss';
import { useSelector } from 'react-redux';
import Utils from '../../redux/utils';
import { useTranslation } from 'react-i18next';

const PageDataLength = [6, 10, 20, 50];

const feedback = () => {
  const user = Utils.getCurrentUser();
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [total, setTotal] = useState(0);
  const [selectedDbId, setSelectedDbId] = useState(3);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [recipeCount, setRecipeCount] = useState(0);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState<any>();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState<any>({});
  const { currentCompany } = useSelector((state: any) => state.Auth);
  const { t } = useTranslation('translation', {
    keyPrefix: 'feedbackPage',
  });

  // React.useEffect(() => {
  //   pageData();
  // }, [currentCompany]);
  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);

    setSortData(() => arr);
  };

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <div className="dotOption">{t('view')}</div>,
      icon: <img src={Eye} width={15} className="iconDropdown" />,
    },
  ];

  const columns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('no')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('id', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      render: (data: any, record: any, index: any) => (
        <div className="col3 cellSpacing">{record?.id}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('type')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('issue_type', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'issue_type',
      key: 'issue_type',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return <div className="col3 cellSpacing">{record?.issue_type}</div>;
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('selected_screen')}</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('screen', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'screen',
      key: 'screen',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 feedbackListViewScreen">{record?.screen}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('description')}</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('feedback', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'feedback',
      key: 'feedback',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 feedbackListViewScreen feedbackMinWidth">
          {record?.feedback?.length > 40
            ? record?.feedback?.substring(0, 40) + '...'
            : record?.feedback}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('created')}</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('created_at', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="feedbackCreatedAt">
          <div className="feedbackUsernameListView">{record?.user_name}</div>
          <Tooltip
            title={
              <div>
                <div className="tooltipHead">Created At</div>
                <div className="tootipText">
                  {moment(UTCtoLocal(record?.created_at)).format(
                    'DD-MM-YYYY HH:mm:ss'
                  )}
                </div>
              </div>
            }
          >
            <div className="feedbackCreatedAtValue">
              {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY')}
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          menu={{
            onClick: handleDropDownClick,
            items: items,
          }}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a
            onClick={() => {
              setSelectedMenu(record?.id);
            }}
          >
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'FeedBack'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, currentCompany]);

  const handleFeedbackDialog = async () => {
    const feedback = await FeedbackApi.getFeedbackById(selectedMenu);
    setDialogData(feedback?.data);
    setOpenDialog(true);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1:
        handleFeedbackDialog();

        break;

      default:
    }
  };

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const pageData = async () => {
    setRecipeLoader(true);
    const variationData = await FeedbackApi.getFeedbacks(dataLength, current);
    if (variationData === undefined || !variationData?.success) {
      setRecipeCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(variationData?.data?.ingredients_count);
      setTotal(variationData?.data?.pagination?.total);
      setSortData(variationData?.data?.items);
    }
    setRecipeLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setRecipeLoader(true);
    const id = selectedDbId;
    const searchResultofVariations = await FeedbackApi.getFeedbackBySearch(
      dataLength,
      keyword
    );
    if (
      searchResultofVariations === undefined ||
      !searchResultofVariations?.success
    ) {
      setRecipeCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(searchResultofVariations?.data?.ingredients_count);
      setTotal(searchResultofVariations?.data?.pagination?.total);
      setSortData(searchResultofVariations?.data?.items);
    }
    setRecipeLoader(false);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      <FeedbackView
        open={openDialog}
        handleClose={handleClose}
        data={dialogData}
      />
      <Breadcrumb
        heading={t('feedback_name')}
        breadcrumbIcon
        iconAction={() => alert('test')}
        button={''}
        perm_status={permissionStatus}
      />
      <Page title="Feedback">
        <Row className="listingTopFilter">
          <Col lg={10} md={24}>
            <div style={{ display: 'flex', marginRight: '100px' }}>
              <ListCount count1={recipeCount} title1={t('total_feedback')} />
            </div>
          </Col>
          <Col lg={14} md={24}>
            <div className="search-wrapper">
              <div className="search-box">
                <SearchBox
                  action={onSearch}
                  placeholderText={t('search_placeholder')}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col lg={24} md={24}>
            {!recipeLoader ? (
              <ListViewWithoutExpand
                limit={dataLength}
                items={items}
                dataSource={[...sortData] || []}
                columns={columns}
              />
            ) : (
              <div className="loader-wrapper">
                <Spin spinning={recipeLoader} className="recipeLoader" />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
};

export default feedback;
