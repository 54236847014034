import React, { useState, useEffect } from 'react';
import {
  MenuProps,
  Modal,
  Select,
  SelectProps,
  Dropdown as DropDown,
  Space,
} from 'antd';
import { Dropdown, IngredientDialog } from '../index';
import IngredientActions from '../../redux/middleware/ingredient';
import Delete from '../../assets/images/Delete.svg';
import ArrowDown from '../../assets/images/ArrowDown.png';
import Eye from '../../assets/images/Eye.svg';
import './style.scss';
import { handleFloat } from '../../helper/sortData';
import DonutChart from '../../components/DonutChart';
type Props = {
  database: any;
  handleIndex: any;
  handleIngredient: any;
  handleRemoveIngredient: any;
  ingredients: any;
  AddIngredient: any;
  handleChangeDatabase: any;
  handleIngredientName: any;
  handleIngredientWeight: any;
  handleIngredientWeightUnit: any;
  removeIngredientName?: any;
  index: any;
  update: any;
  unit: any;
  errorList?: any;
  errorStatus?: boolean;
};

const StepTwo = (props: Props) => {
  let {
    database,
    handleIndex,
    handleIngredient,
    handleRemoveIngredient,
    AddIngredient,
    handleChangeDatabase,
    index,
    ingredients,
    handleIngredientName,
    handleIngredientWeight,
    handleIngredientWeightUnit,
    removeIngredientName,
    unit,
  } = props;
  const [popUp, setPopUp] = useState<any>(null);

  const items: MenuProps['items'] = unit?.map((data: any) => {
    return {
      key: `${data?.id}`,
      label: <div className="dropdownOptions">{data?.unit}</div>,
    };
  });

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [ingredientIndex, setIngredientIndex] = useState<any>();
  const [ingredientName, setIngredientName] = useState('');
  const [option, setOption] = useState<SelectProps['options']>([
    { value: '', label: '', key: '' },
  ]);
  const [ingredientData] = useState(ingredients || []);

  const fetchDatabase = async () => {
    const data = await IngredientActions.getDatabase();

    const options: SelectProps['options'] = data?.data?.map((country: any) => {
      return {
        value: country?.db_name,
        label: country?.db_name,
        key: country?.id,
      };
    });

    setOption(options);
  };
  // const fetchIngredients = async () => {
  //   const data = await IngredientActions.getInventory(database?.key, 'a');
  //   setIngredientData(data?.data?.items);
  // };

  useEffect(() => {
    fetchDatabase();
    window.scrollTo(0, 0);
  }, []);

  const handleDialog = () => {
    setOpen(false);
    // fetchIngredients();
    removeIngredientName(ingredientIndex);
  };

  const handleDelete = () => {
    handleIndex(-1);
    setOpenDelete(false);
  };

  const handleDeleteContinue = (index: number) => {
    handleRemoveIngredient(index);
    handleIndex(-1);
    setOpenDelete(false);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    const unitObject = unit.filter((data: any) => data?.id == option);
    handleIngredientWeightUnit(unitObject[0], ingredientIndex);
  };

  const DropDownmenuProps = {
    items: items,
    onClick: handleDropDownClick,
  };

  const handleSuccesDialog = () => {
    setOpen(false);
    // fetchIngredients();
  };

  return (
    <div className="recipeBuilderWizardStepTwo">
      {open ? (
        <IngredientDialog
          handleDialog={handleDialog}
          open={open}
          component={'recipe builder'}
          handleSuccesDialog={handleSuccesDialog}
          name={ingredientName}
          handleIngredient={(data: any) => {
            handleIngredient(data, ingredientIndex);
            setIngredientName(data?.ingredient_name);
          }}
        />
      ) : null}
      <Modal
        title={<div className="title font">Confirmation</div>}
        centered
        open={openDelete}
        onCancel={handleDelete}
        width={480}
        footer={null}
        className="DialogHeight"
      >
        <div className="deleteDialogText">
          {` Are you sure want to delete “${ingredients[index]?.ingredient_name}” ingredient?`}
        </div>
        <div className="butonGroupDialog">
          <div className="wizard-button" onClick={() => handleDelete()}>
            Cancel
          </div>
          <div
            className="continue wizard-button"
            onClick={() => handleDeleteContinue(index)}
          >
            Continue
          </div>
        </div>
      </Modal>
      <div className="step2-head">Find Ingredients</div>
      <div className="wizardDropdown">
        <div className="wizardLabel">
          Select Database <span className="sterik">*</span>
        </div>
        <Select
          size={'large'}
          defaultValue={database?.label}
          onChange={(e: any, data: any) => {
            handleChangeDatabase(e, data);
          }}
          style={{ width: 560 }}
          options={option}
        />
      </div>
      <div>
        <div className={'wizardSubContainer'}>
          <div className="wizardLabel step2-calorie-label">
            <div>
              Add Ingredients <span className="sterik">*</span>
            </div>
            <div className="">Calories</div>
          </div>

          {ingredients?.map((data: any, index: number) => {
            return (
              <div className="gramContainer" key={index}>
                <Dropdown
                  handleIngredientName={(text: any) => {
                    handleIngredientName(text, index);
                    setIngredientName(text);
                  }}
                  ingredientName={data?.ingredient_name}
                  openDialog={() => {
                    setIngredientIndex(index);
                    setOpen(true);
                  }}
                  ingredientData={ingredientData}
                  database={database}
                  handleIngredient={(data: any) => {
                    handleIngredient(data, index);
                  }}
                  component={'recipeBuilder'}
                />

                <div className="gramSubContainer">
                  <input
                    className="gramValue"
                    placeholder="0"
                    type={'number'}
                    value={
                      data.quantity
                        ? handleFloat(data?.quantity).toString()
                        : ''
                    }
                    onChange={(e) => {
                      if (+e.target.value <= 999)
                        handleIngredientWeight(+e.target.value, index);
                    }}
                  />
                  <div className="gramBoxWrapper">
                    <div className="gramBox">
                      <DropDown
                        menu={DropDownmenuProps}
                        trigger={['click']}
                        placement="bottomRight"
                        arrow={{ pointAtCenter: true }}
                      >
                        <a onClick={() => setIngredientIndex(index)}>
                          <Space>
                            <div>{data?.unit}</div>{' '}
                            <img src={ArrowDown} alt="" />
                          </Space>
                        </a>
                      </DropDown>
                    </div>
                  </div>
                </div>

                <img
                  src={Delete}
                  width={15}
                  alt=""
                  className="deleteButon"
                  onClick={() => {
                    handleIndex(index);
                    handleDeleteContinue(index);
                    // data?.ingredient_name == ''
                    //   ? handleDeleteContinue(index)
                    //   : setOpenDelete(true);
                  }}
                />
                <div className="calorie-container-each-ingredient">
                  <div className="calorie-each-value-container">
                    {isNaN(data?.calories_per_unit * parseFloat(data?.quantity))
                      ? 0
                      : Math.round(
                          Number(
                            data?.calories_per_unit * parseFloat(data?.quantity)
                          )
                        )}
                  </div>
                  <DonutChart
                    popUp={popUp}
                    setPopUp={setPopUp}
                    index={index}
                    data={data}
                    component={'wizard'}
                  >
                    <img
                      src={Eye}
                      width={20}
                      className="ingredient-unmatach-select-icons"
                    />
                  </DonutChart>
                </div>
              </div>
            );
          })}
          {/* {errorStatus && (
          <div className="subRecipeErrorMessages">
            {errorList['recipe_type_id'][0]}
          </div>
        )} */}
        </div>

        <div className="addButton">
          <div onClick={AddIngredient} className="addButton-subContainer">
            + Add New Row
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
