import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  Page,
  Stepper,
  Breadcrumb,
  RecipeStepOne,
  RecipeStepTwo,
  ResponseDialog,
} from '../../components';
import RecipeApiActions from '../../redux/middleware/recipe-api';
import './recipeWizard.scss';
import Attributes from '../../redux/middleware/attributes';
import { Alert, Spin } from 'antd';
import Utils from '../../redux/utils';
import { useSelector } from 'react-redux';
import { checkAuthentication } from '../../helper/checkAuthentication';

type allergens = {
  id: number;
  name: string;
  status: number;
  created_at: string;
  updated_at: string;
};

type Database = {
  value: any;
  label: any;
  key: any;
};

type additionalInfo = {
  id: number;
  attribute_name: string;
  status: number;
  created_at: string;
  updated_at: string;
};

const recipeWizard = () => {
  const user = Utils.getCurrentUser();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [database, setDatabase] = useState<Database>({
    value: 2,
    label: 'My Market List',
    key: 3,
  });
  const { update, id, variationType, parentId, status } = state;
  const [visible, setVisible] = React.useState(false);
  const [allergenData, setAlergenData] = useState<any[]>();
  const [image, setImage] = React.useState('');
  const [blocked, setBlocked] = React.useState(false);
  const [blockedForEdit, setBlockedForEdit] = React.useState(false);
  const [imageObject, setImageObject] = React.useState({});
  const [category, setCategory] = React.useState<any>();
  const [recipe, setRecipe] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const [alerts, setAlerts] = useState(false);
  const [stepper, setStepper] = React.useState(0);
  const [orderVariation, setOrderVariation] = useState<any[]>([]);
  const [CookingVariation, setCookingVariation] = useState<any[]>();
  const [sizeVariation, setSizeVariation] = useState<any[]>();
  const [variation, setVariation] = useState<any>();
  const [variationList, setVariationList] = useState<any[]>([]);
  const [menu, setMenu] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [errorList, setErrorList] = useState<any>({});
  const { currentShop, currentBrand } = useSelector((state: any) => state.Auth);
  const [currentBrandId] = useState(currentBrand?.brand_id);

  // Step 2 States
  const [ingredients, setIngredients] = useState<any[]>([
    {
      ingredient_name: '',
      unit_cost: 0,
      cost: '',
      small_unit: '',
      type: '',
      total_price: 0,
      quantity: '',
    },
  ]);

  // step 3
  const [stepDeleteDialog, setStepDeleteDialog] = useState(false);
  const [allergen, setAllergen] = useState<allergens[]>([]);
  const [addinfo, setAddinfo] = useState<additionalInfo[]>([]);
  const [steps, setSteps] = useState(['']);
  const [stepIndex, setStepIndex] = useState(0);
  const [calory, setCalory] = useState(0);
  const [carb, setcarb] = useState(0);
  const [fat, setFat] = useState(0);
  const [protein, setProtein] = useState(0);
  const [recipeNote, setRecipeNote] = useState('');
  const [video, setVideo] = useState('');
  const [proposedPrice, setProposedPrice] = React.useState(0);
  const [costRatio, setCostRatio] = React.useState(0);
  const [totalCost, setTotalCost] = React.useState(0);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [finalPrice, setFinalPrice] = React.useState(0);
  const [cookingMenu, setCookingMenu] = React.useState(false);
  const [allergensByDefault, setAllergensByDefault] = useState<any>([]);

  const [prep, setPrep] = useState('');
  const [serving, setServing] = useState<any>(1);

  const [recipes, setRecipes] = useState<any[]>([]);
  const [searchedValue, setSearchedValue] = useState('');
  const [showRecipeView, setShowRecipeView] = useState(false);
  // const [binaryData, setBinaryData] = useState<any>();

  useEffect(() => {
    if (!update && !variationType) {
      setIngredients([
        {
          ingredient_id: 0,
          unit_id: 1,
          unit_cost: '',
          quantity: '',
          ingredient_name: '',
          small_unit: 'gram',
          order: orderVariation[0],
        },
      ]);
      setCategory({
        sub_category: '',
        id: -1,
      });
    }
    if (currentBrand?.brand_id !== currentBrandId) fetchVariationType();
  }, [currentBrand, orderVariation]);

  const onChangeCookingMenu = (e: CheckboxChangeEvent) => {
    setCookingMenu(e.target.checked);
  };

  const handleChangeSearchedValue = (e: any) => {
    setSearchedValue(e.target.value);
  };

  const handleSelectOption = (ingredient: any) => {
    setSearchedValue(ingredient?.name);

    setShowRecipeView(true);
    setRecipes((prevArray) => [...prevArray, { ...ingredient, steps: [''] }]);
  };
  const handleDeleteRecipe = (recipe: any) => {
    setRecipes((current) =>
      current.filter((item) => {
        return item?.id !== recipe?.id;
      })
    );
  };

  const handleAddItem = () => {
    // setShowRecipeView(false);
  };

  const handleAddRecipeStep = (recipe: any, index: any) => {
    let arr = [...recipes];
    arr[index].steps.push('');
    setRecipes(arr);
  };

  const handleChangeDatabase = (value: string | string[], data: any) => {
    setDatabase(data);
  };

  const handleDeleteRecipeStep = (recipe: any, index: any) => {
    const recipeIndex = recipes.findIndex((object) => {
      return object.id === recipe.id;
    });
    let arr = [...recipes];
    arr[recipeIndex].steps.splice(index, 1);
    setRecipes(arr);
  };

  const handleStepValue = (e: any, recipe: any, index: any) => {
    const recipeIndex = recipes.findIndex((object) => {
      return object.id === recipe.id;
    });
    let arr = [...recipes];
    arr[recipeIndex].steps[index] = e.target.value;
    setRecipes(arr);
  };

  const fetchDataById = async (id: any) => {
    const brand = await Utils.getCurrentBrand();
    const data = await RecipeApiActions.getIngredientDetail(
      id,
      brand?.brand_id
    );
    if (data) {
      setLoader(false);
    }

    setImage(data?.data?.general?.thumbnail);
    setDescription(data?.data?.general?.description);
    setVariationList(data?.data?.variation_list);
    setIngredients(
      data?.data.ingredients.map((data: any) => {
        return {
          ...data,
          id: data?.ingredient_id,
          ingredient_name: data?.ingredient_name,
          unit_id: data?.unit_id,
          quantity: data?.quantity,
          small_unit: data?.unit,
          // unit_cost: data?.cost_per_ingredient ? data?.cost_per_ingredient : 0,
          divided_by: data?.divided_by,
          protein_per_unit: data?.protein_per_unit,
          carb_per_unit: data?.carb_per_unit,
          calories_per_unit: data?.calories_per_unit,
          fat_per_unit: data?.fat_per_unit,
          order: {
            id: data?.order_type_id,
            name: data?.order_type_mode, // order_type_name for order types name and order_type_mode for All/Limited
          },
          total_price: data?.cost_per_ingredient
            ? data?.cost_per_ingredient
            : 0,
        };
      })
    );
    setCategory({
      sub_category: data?.data?.general?.menu_category,
      id: data?.data?.general?.menu_category_id,
    });
    setAllergen(data?.data.general.allergens);
    setTotalCost(data?.data.general.total_cost);
    setProposedPrice(data?.data?.general?.propose_price);
    setCostRatio(data?.data?.general?.fcr);
    setFinalPrice(data?.data?.general?.final_price);
    setSteps(data?.data?.general?.steps);
    setCalory(data?.data?.general?.calorie);
    setFat(data?.data?.general?.fat);
    setcarb(data?.data?.general?.carb);
    setProtein(data?.data?.general?.protein);
    setPrep(data?.data?.general?.prep_time);
    setRecipeNote(data?.data?.general?.instruction);
    setAddinfo(data?.data?.general?.additional_attributes);
    setRecipeNote(data?.data.general.recipe_note);
    setRecipe(data?.data?.general?.recipe_name);
    setServing(data?.data?.general?.servings);

    setVariation(
      data?.data?.general?.is_variation == 'yes' && {
        variation_type_id: data?.data.variations?.variation_type_id,
        variation_type: data?.data.variations?.variation_type,
        id: data?.data.variations?.variation_id,
        name: data?.data.variations?.variation,
        instructions: 'no text',
      }
    );
    setMenu(data?.data?.general?.is_variation == 'yes' ? true : false);

    setSteps(
      data?.data?.cooking_methods[0]?.cooking_method == ''
        ? data?.data?.cooking_methods[0]?.steps
        : []
    );
    setCookingMenu(
      data?.data?.cooking_methods[0]?.cooking_method == '' ? false : true
    );

    setRecipes(
      data?.data?.cooking_methods[0]?.cooking_method == ''
        ? []
        : data?.data?.cooking_methods.map((data: any) => {
            return {
              id: data?.cooking_method_id,
              name: data?.cooking_method,
              steps: data?.steps,
            };
          })
    );
    setShowRecipeView(
      data?.data?.cooking_methods[0]?.cooking_method == '' ? false : true
    );
  };

  const fetchVariationType = async () => {
    const { data } = await RecipeApiActions.getVariationType();

    data?.items.map(async (data: any, index: number) => {
      const variationData = await RecipeApiActions.getVariations(data?.id);
      if (index == 0 && variationData?.data?.length == 0) {
        let responseDialogData = {
          type: 'error',
          messageArray: [`Please create order type, before!`],
          OnClick: () => {
            navigate('/app/recipes');
          },
        };
        ResponseDialog(responseDialogData);
      }

      index == 0 &&
      variationData?.data?.items?.length &&
      orderVariation.length < 1
        ? setOrderVariation([
            {
              created_at: '2023-02-01 20:10:39',
              id: variationData.data.items
                .map((data: any) => data?.id)
                .toString(),
              name: 'All',
              variation_type: 'Order Type',
              variation_type_id: variationData.data.items[0].variation_type_id,
            },
            ...variationData.data.items,
          ])
        : index == 1
        ? setCookingVariation(variationData?.data?.items)
        : setSizeVariation(variationData?.data?.items);
    });
  };

  useEffect(() => {
    if (update && currentBrandId !== currentBrand?.brand_id) {
      navigate('/app/recipes');
    }
  }, [currentBrand, currentShop]);

  const fetchAllergen = async () => {
    const data = await Attributes.getAllergens();
    setAlergenData(data?.data);
  };

  // const fetchDefaultImage = async () => {
  //   const defaultImage = await loadImage();
  //   setBinaryData(defaultImage);
  // };

  useEffect(() => {
    // fetchDefaultImage();
    checkAuthentication(user, navigate);
    fetchAllergen();
    fetchVariationType();
    if (update) {
      if (status) {
        let responseDialogData = {
          type: 'error',
          messageArray: [
            `This Recipe is part of active menu, Kindly change its status to inactive before Updating it`,
          ],
          OnClick: () => {
            navigate('/app/recipes');
          },
        };
        ResponseDialog(responseDialogData);
      }
      setLoader(true);
      fetchDataById(id);
    }
    if (variationType) {
      setBlocked(true);
      fetchDataById(id);
    }
  }, []);

  useEffect(() => {
    if (variationList?.length >= 2) {
      setBlockedForEdit(true);
    }
  }, [variationList]);

  const handleImage = (url: any, data: any) => {
    if (url !== '') {
      const invalidSize = data?.target?.files[0].size / 1024 / 1024 > 2;
      if (invalidSize) {
        // eslint-disable-next-line no-alert
        alert('Invalid file size, Image should be less than 2 MB');
      } else {
        setImage(url);
        setImageObject(data?.target?.files[0]);
      }
    } else {
      setImage(url);
      setImageObject({});
    }
  };

  const handleDescription = (data: string) => {
    setDescription(data);
  };

  const handleRecipe = (data: string) => {
    setRecipe(data);
  };

  const onChangeMenu = (e: CheckboxChangeEvent) => {
    setMenu(e.target.checked);
  };

  const handleBackStepper = (index: number) => {
    if (index == -1) navigate('/app/recipes');

    if (stepper !== 0) {
      setVisible(false);
      setAlerts(false);
      setStepper(index);
    }
  };
  const handleNextStepper = async (index: number) => {
    const company = Utils.getCurrentCompany();
    const brand = await Utils.getCurrentBrand();
    const shop = await Utils.getCurrentShop();

    // if (stepper == 0 && ingredients?.length == 1) {
    //   setAlertMessage('Please add two or more Ingredient');
    //   setVisible(true);
    //   setAlerts(false);
    //   window.scrollTo(0, 0);
    //   return false;
    // }

    if (index <= 2) {
      setVisible(false);
      setAlerts(false);
      setStepper(index);
      window.scrollTo(0, 0);
    }

    if (index == 2 && !update && !variationType) {
      setErrorList({});
      setErrorStatus(false);
      const params = {
        general: {
          menu_category_id: category?.id || null,
          recipe_name: recipe,
          unit_id: 1,
          currency_id: brand?.currency_id || null,
          brand_id: brand?.brand_id || null,
          company_id: company?.id,
          shop_id: shop?.id || 0,
          is_verified: calory > 0 && protein > 0 && carb > 0 && fat > 0 ? 1 : 0,
          verified_by: 'Head of Culinary',
          created_by: 2,
        },
        ingredients: ingredients.map((data: any) => {
          return {
            ingredient_id: data?.id,
            ingredient_name: data?.ingredient_name,
            unit_id: data?.unit_id,
            quantity: data?.quantity,
            cost_per_ingredient: data?.total_price ? data?.total_price : 0,
            order_type_id: data?.order?.id,
          };
        }),
        variations: !menu
          ? {}
          : {
              variation_type_id: variation?.variation_type_id,
              variation_type: variation?.variation_type,
              variation_id: variation?.id,
              variation: variation?.name,
              instructions: 'no text',
            },

        costing: {
          total_cost: totalCost,
          propose_price: proposedPrice,
          final_price: finalPrice === 0 ? proposedPrice : finalPrice,
          fcr: costRatio,
          menu_price: '',
        },

        production: {
          description: description,
          recipe_note: recipeNote,
          allergens_id: allergen.map((data) => data?.id).toString(),
          prep_time: prep,
          calorie: calory,
          protein: protein,
          carb: carb,
          fat: fat,
          additional_attribute_id: addinfo.map((data) => data?.id).toString(),
          servings: serving,
        },
        cooking_methods:
          recipes.length == 0
            ? [
                {
                  cooking_method_id: 0,
                  cooking_method: '',
                  steps: steps,
                },
              ]
            : recipes.map((data) => {
                return {
                  cooking_method_id: data?.id,
                  cooking_method: data?.name,
                  steps: data?.steps,
                };
              }),
      };
      let responseDialogData;
      setLoader(true);
      try {
        const response = await RecipeApiActions.createRecipe(
          params,
          imageObject &&
            Object.keys(imageObject).length === 0 &&
            Object.getPrototypeOf(imageObject) === Object.prototype
            ? {}
            : imageObject
        );
        setLoader(false);
        if (!response?.success) {
          setErrorList(response?.error);
          setErrorStatus(true);
          responseDialogData = {
            OnClick: () => {
              setStepper(0);
            },
            type: 'error',
            messageArray: ['Validation Failed!', 'Something Went Wrong'],
          };
          ResponseDialog(responseDialogData);
        } else {
          navigate('/app/recipes');

          // responseDialogData = {
          //   OnClick: () => {
          //     navigate('/app/recipes');
          //   },
          //   type: 'success',
          //   messageArray: [` Your ${recipe}`, ` has been Created`],
          // };
          // ResponseDialog(responseDialogData);
        }
      } catch (e) {
        console.log(e);
      }
    } else if (index == 2 && update) {
      setErrorList({});
      setErrorStatus(false);
      const params = {
        general: {
          parent_recipe_id: parentId,
          recipe_id: id,
          menu_category_id: category?.id || null,
          recipe_name: recipe,
          unit_id: 1,
          currency_id: brand?.currency_id || 0,
          company_id: company?.id || 0,
          brand_id: brand?.brand_id || 0,
          shop_id: shop?.id || 0,
          is_verified: calory > 0 && protein > 0 && carb > 0 && fat > 0 ? 1 : 0,
          verified_by: 'Head of Culinary',
          created_by: 2,
        },
        ingredients: ingredients.map((data: any) => {
          return {
            ingredient_id: data?.id,
            ingredient_name: data?.ingredient_name,
            unit_id: data?.unit_id,
            quantity: data?.quantity,
            cost_per_ingredient: data?.total_price ? data?.total_price : 0,
            order_type_id: data?.order?.id,
          };
        }),
        variations: !menu
          ? {}
          : {
              variation_type_id: variation?.variation_type_id,
              variation_type: variation?.variation_type,
              variation_id: variation?.id,
              variation: variation?.name,
              instructions: 'no text',
            },

        costing: {
          total_cost: totalCost,
          propose_price: proposedPrice,
          final_price: finalPrice === 0 ? proposedPrice : finalPrice,
          fcr: costRatio,
          menu_price: '',
        },

        production: {
          thumbnail:
            imageObject &&
            Object.keys(imageObject).length === 0 &&
            Object.getPrototypeOf(imageObject) === Object.prototype
              ? image?.split('/').pop()
              : '',

          description: description,
          recipe_note: recipeNote,
          allergens_id: allergen.map((data) => data?.id).toString(),
          prep_time: prep,
          calorie: calory,
          protein: protein,
          carb: carb,
          fat: fat,
          additional_attribute_id: addinfo.map((data) => data?.id).toString(),
          servings: serving,
        },
        cooking_methods:
          recipes.length == 0
            ? [
                {
                  cooking_method_id: 0,
                  cooking_method: '',
                  steps: steps.length > 0 ? steps : ['No steps available'],
                },
              ]
            : recipes.map((data) => {
                return {
                  cooking_method_id: data?.id,
                  cooking_method: data?.name,
                  steps: data?.steps,
                };
              }),
      };
      setLoader(true);
      const response = await RecipeApiActions.updateRecipe(
        params,
        imageObject &&
          Object.keys(imageObject).length === 0 &&
          Object.getPrototypeOf(imageObject) === Object.prototype
          ? {}
          : imageObject,
        'PUT',
        id
      );
      setLoader(false);
      if (!response?.success) {
        setErrorList(response?.error);
        setErrorStatus(true);
        // responseDialogData = {
        //   OnClick: () => {
        //     setStepper(0);
        //   },
        //   type: 'error',
        //   messageArray: ['Validation Failed!', 'Something Went Wrong'],
        // };
        // ResponseDialog(responseDialogData);
      } else {
        navigate('/app/recipes');

        // responseDialogData = {
        //   OnClick: () => {
        //     navigate('/app/recipes');
        //   },
        //   type: 'success',
        //   messageArray: [` Your ${recipe}`, ` has been Updated`],
        // };
        // ResponseDialog(responseDialogData);
      }
    } else if (index == 2 && variationType) {
      setErrorList({});
      setErrorStatus(false);
      const params = {
        general: {
          thumbnail:
            imageObject &&
            Object.keys(imageObject).length === 0 &&
            Object.getPrototypeOf(imageObject) === Object.prototype
              ? image?.split('/').pop()
              : '',
          parent_recipe_id: parentId,
          recipe_id: id,
          menu_category_id: category?.id || null,
          recipe_name: recipe,
          unit_id: 1,
          currency_id: brand?.currency_id,
          company_id: company?.id,
          brand_id: brand?.brand_id,
          shop_id: shop?.id || 0,
          is_verified: calory > 0 && protein > 0 && carb > 0 && fat > 0 ? 1 : 0,
          verified_by: 'Head of Culinary',
          created_by: 2,
        },
        ingredients: ingredients.map((data: any) => {
          return {
            ingredient_id: data?.id,
            ingredient_name: data?.ingredient_name,
            unit_id: data?.unit_id,
            quantity: data?.quantity,
            cost_per_ingredient: data?.total_price ? data?.total_price : 0,
            order_type_id: data?.order?.id,
          };
        }),
        variations:
          variation &&
          Object.keys(variation).length === 0 &&
          Object.getPrototypeOf(variation) === Object.prototype
            ? {}
            : {
                variation_type_id: variation?.variation_type_id,
                variation_type: variation?.variation_type,
                variation_id: variation?.id,
                variation: variation?.name,
                instructions: 'no text',
              },

        costing: {
          total_cost: totalCost,
          propose_price: proposedPrice,
          final_price: finalPrice === 0 ? proposedPrice : finalPrice,
          fcr: costRatio,
          menu_price: '',
        },

        production: {
          description: description,
          recipe_note: recipeNote,
          allergens_id: allergen.map((data) => data?.id).toString(),
          prep_time: prep,
          calorie: calory,
          protein: protein,
          carb: carb,
          fat: fat,
          additional_attribute_id: addinfo.map((data) => data?.id).toString(),
          servings: serving,
        },
        cooking_methods:
          recipes.length == 0
            ? [
                {
                  cooking_method_id: 0,
                  cooking_method: '',
                  steps: steps,
                },
              ]
            : recipes.map((data) => {
                return {
                  cooking_method_id: data?.id,
                  cooking_method: data?.name,
                  steps: data?.steps,
                };
              }),
      };

      let responseDialogData;
      setLoader(true);
      const response = await RecipeApiActions.createRecipe(
        params,
        imageObject &&
          Object.keys(imageObject).length === 0 &&
          Object.getPrototypeOf(imageObject) === Object.prototype
          ? {}
          : imageObject
      );
      setLoader(false);
      if (!response?.success) {
        setErrorList(response?.error);
        setErrorStatus(true);
        responseDialogData = {
          OnClick: () => {
            setStepper(0);
          },
          type: 'error',
          messageArray: [
            'Validation Failed!',
            'Variation name already exist against this Recipe Name',
          ],
        };
        ResponseDialog(responseDialogData);
      } else {
        navigate('/app/recipes');

        // responseDialogData = {
        //   OnClick: () => {
        //     navigate('/app/recipes');
        //   },
        //   type: 'success',
        //   messageArray: [` Your ${recipe}`, ` has been created`],
        // };
        // ResponseDialog(responseDialogData);
      }
    }
  };

  const handleChange = (data: any) => {
    setCategory(data);
  };

  const handleVariationName = (data: any) => {
    setVariation((prev: any) => {
      return { ...prev, name: data };
    });
  };

  const handleVariation = (data: any) => {
    setVariation(data);
  };

  const calculateNutritionValues = () => {
    let c_carb = 0;
    let c_fat = 0;
    let c_protein = 0;
    let c_calorie = 0;

    for (let index = 0; index < ingredients.length; index += 1) {
      const { quantity } = ingredients[index];
      const {
        protein_per_unit,
        carb_per_unit,
        calories_per_unit,
        fat_per_unit,
      } = ingredients[index];
      c_carb += carb_per_unit * parseFloat(quantity);
      c_fat += fat_per_unit * parseFloat(quantity);
      c_protein += protein_per_unit * parseFloat(quantity);
      c_calorie += calories_per_unit * parseFloat(quantity);
    }

    setcarb(c_carb);
    setProtein(c_protein);
    setFat(c_fat);
    setCalory(c_calorie);
  };

  const checkEmptyIngredients = (index: number) => {
    if (index == 1) {
      const wrongIngredient = ingredients.filter((data: any) => {
        if (
          data?.ingredient_id == -1 ||
          data?.quantity == '' ||
          data?.quantity == '0'
        )
          return { unit: data?.carb_per_unit, qty: data?.quantity };
      });
      if (wrongIngredient.length) {
        setMessages([
          'Please ensure all required fields are filled out correctly, and unused fields are removed.',
        ]);
        setVisible(false);
        setAlerts(true);
        window.scrollTo(0, 0);
        return true;
      } else {
        return false;
      }
    }
  };

  // const checkEmptyStep = (index: number) => {
  //   if (index == 2) {
  //     if (
  //       (!cookingMenu && steps?.length != 0 && steps[0] !== '') ||
  //       (cookingMenu &&
  //         recipes?.length != 0 &&
  //         recipes[0]?.steps?.length != 0 &&
  //         !recipes.some(
  //           (data) =>
  //             data?.steps.filter((step: string) => step == '').length > 0
  //         ))
  //     ) {
  //       return false;
  //     } else {
  //       setMessages([
  //         'Steps is mandatory. If there is an empty step then it should be removed!',
  //       ]);
  //       setAlerts(true);
  //       setVisible(false);
  //       window.scrollTo(0, 0);
  //       return true;
  //     }
  //   }
  // };

  const handleContinue = () => {
    const checkIngredient = checkEmptyIngredients(stepper + 1);
    // const checkSteps = checkEmptyStep(stepper + 1);
    if (
      (stepper == 0 &&
        (category == '' ||
          category?.sub_category == '' ||
          recipe == '' ||
          // (+proposedPrice === 0 && +finalPrice === 0) ||
          ingredients?.length == 0 ||
          checkIngredient)) ||
      (menu && !variation) ||
      (menu && variation?.name === '') ||
      (stepper == 1 && serving == '')
    ) {
      setVisible(true);
      setAlerts(false);
      setAlertMessage('');
      window.scrollTo(0, 0);
      return;
    }

    if (stepper == 0) calculateNutritionValues();
    if (stepper < 2) {
      setVisible(false);
      setAlerts(false);
      setAlertMessage('');
      handleNextStepper(stepper + 1);
    }
  };

  const handleCostIngredient = (
    dataArray: any,
    data: any,
    key: any,
    index: any
  ) => {
    if (dataArray[index]?.divided_by != null)
      if (key == 'quantity' && data != '') {
        dataArray[index]['total_price'] = Number(
          (
            (+dataArray[index]['unit_cost'] / dataArray[index]['divided_by']) *
            +data
          ).toFixed(2)
        );
      } else dataArray[index]['total_price'] = 0;
    // dataArray[index]['total_price'] = Number(
    //   (
    //     +dataArray[index]['unit_cost'] / dataArray[index]['divided_by']
    //   ).toFixed(2)
    // );
    else {
      dataArray[index]['total_price'] = 0;
    }
    return dataArray;
  };

  const handleTotalPrice = (dataSource: Array<any>) => {
    let total = dataSource.map((data) => data?.total_price);
    const price = total.reduce((partialSum, a) => partialSum + a, 0);
    setTotalCost(price);
  };

  const handleIngredient = (data: any, index: number, key: any) => {
    let dataArray = ingredients;

    dataArray[index][key] = data;
    dataArray = handleCostIngredient(dataArray, data, key, index);

    key == 'quantity' && handleTotalPrice(dataArray);
    setIngredients([...dataArray]);
  };

  const autoFetchAllergen = (dataSource: any) => {
    let arr = dataSource.map((data: any) => {
      if (data?.ingredient_name != '' || data?.unit_cost != 0)
        return data.allergens_id;
    });

    arr = arr
      .filter(function (element: any) {
        return element !== undefined;
      })
      ?.filter((data: any) => data !== null);

    arr = arr?.map((data: any) => data?.split(',')).flat();

    let allergenId = [...new Set(arr)];
    const FetchAllergen = allergenId?.map((data) => {
      let fetchObject = allergenData?.filter(
        (element: any) => element?.id == data
      );
      return fetchObject;
    });
    setAllergensByDefault(FetchAllergen.flat());
    setAllergen(FetchAllergen?.flat());
  };

  const handleRemoveIngredient = (index: number) => {
    let DataArray = ingredients;
    DataArray?.splice(index, 1);
    handleTotalPrice(DataArray);
    setIngredients([...DataArray]);
    autoFetchAllergen(DataArray);
  };

  const handleIngredientObject = (data: any, index: number) => {
    let dataArray = ingredients;

    const checkDuplicateData = dataArray.some(
      (ingredient) => ingredient?.id === data?.id
    );
    const checkDuplicateField =
      dataArray.findIndex(
        (ingredientObject) => ingredientObject?.id === data?.id
      ) === index;

    if (!checkDuplicateField && checkDuplicateData) {
      dataArray.splice(index, 1);
      setIngredients(() => [...dataArray]);
      setMessages([
        'Duplication of ingredients is not allowed! The field automatically removed, when you try to duplicate ingredients.',
      ]);
      setVisible(false);
      setAlerts(true);
      window.scrollTo(0, 0);
    } else if (checkDuplicateField && checkDuplicateData) {
      setIngredients([...dataArray]);
    } else {
      dataArray[index] = {
        ...data,
        order: dataArray[index]?.order,
        quantity: '',
      };
      handleTotalPrice(dataArray);
      autoFetchAllergen(dataArray);
      setIngredients([...dataArray]);
    }
  };

  const handleIngredientOrder = (data: any, index: number) => {
    let dataArray = ingredients;
    dataArray[index] = { ...dataArray[index], order: data };

    setIngredients([...dataArray]);
  };

  const addIngredient = () => {
    setIngredients((prevArray) => [
      ...prevArray,
      {
        ingredient_id: 0,
        unit_id: 1,
        unit_cost: '',
        quantity: '',
        ingredient_name: '',
        small_unit: 'gram',
        order: orderVariation[0],
      },
    ]);
  };

  const handleallergenCheck = (e: CheckboxChangeEvent, data: any) => {
    const { checked } = e.target;
    if (
      allergensByDefault.find((val: any) => val?.id == data?.id) == undefined
    ) {
      if (checked) {
        setAllergen((prev) => [...prev, data]);
      } else {
        let dataArray = allergen;
        const updated = dataArray.filter((val) => val?.id != data?.id);
        setAllergen([...updated]);
      }
    }
  };
  const handleaddionalinfocheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    data: additionalInfo
  ) => {
    const { checked } = e.target;

    if (checked) {
      setAddinfo((prev) => [...prev, data]);
    } else {
      let dataArray = addinfo;
      const updated = dataArray.filter((val) => val?.id !== data?.id);

      setAddinfo([...updated]);
    }
  };
  const addSteps = () => {
    setSteps((prev) => [...prev, '']);
  };

  const handleChangeStep = (text: any, index: number) => {
    let DataArray = steps;
    DataArray[index] = text;
    setSteps([...DataArray]);
  };
  const handleDeleteStep = (index: number) => {
    let DataArray = steps;
    DataArray.splice(index, 1);
    setSteps([...DataArray]);
    setStepDeleteDialog(false);
  };

  const handleCostRatio = (data: any) => {
    setCostRatio(data);
    data == '' ? setProposedPrice(0) : handleProposedPrice(+data);
  };

  const handleFinalPrice = (data: any) => {
    setFinalPrice(data);
  };

  const handleProposedPrice = (data: any) => {
    setProposedPrice((totalCost * 100) / data);
  };

  const handleTotalCost = (data: any) => {
    setTotalCost(data);
  };
  const handleStepper = (value: number) => {
    if (value > stepper) handleContinue();
    else setStepper(value);
  };
  return (
    <React.Fragment>
      <Breadcrumb
        heading="Add New Recipe"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/recipes');
        }}
      />

      <Page title="Add New Recipe">
        {visible && (
          <Alert
            message="Error"
            description={
              alertMessage
                ? alertMessage
                : 'Please complete all required fields to proceed'
            }
            type="error"
            showIcon
            closable
            className="wizardAlert"
            afterClose={() => setVisible(false)}
          />
        )}
        {alerts &&
          messages?.map((data: any, index: any) => (
            <Alert
              message="Error"
              description={data}
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setAlerts(false)}
              key={index}
            />
          ))}
        <Stepper
          stepper={stepper}
          component={'recipe'}
          onStepperChange={handleStepper}
        />

        <Spin
          spinning={loader}
          size="large"
          className="loadIngredient recipeBuilderloaderHeight"
        >
          <div className="wizardContainer">
            {stepper === 0 ? (
              <RecipeStepOne
                onChangeMenu={onChangeMenu}
                menu={menu}
                variation={variation}
                handleVariationName={handleVariationName}
                handleVariation={handleVariation}
                sizeVariation={sizeVariation}
                handleIngredientOrder={handleIngredientOrder}
                orderVariation={orderVariation}
                category={category?.sub_category}
                handleChange={handleChange}
                blockedForEdit={blockedForEdit}
                recipe={recipe}
                handleRecipe={handleRecipe}
                proposedPrice={proposedPrice}
                blocked={blocked}
                costRatio={costRatio}
                totalCost={totalCost}
                finalPrice={finalPrice}
                handleCostRatio={handleCostRatio}
                handleFinalPrice={handleFinalPrice}
                handleProposedPrice={handleProposedPrice}
                handleTotalCost={handleTotalCost}
                handleChangeDatabase={handleChangeDatabase}
                database={database}
                addIngredient={addIngredient}
                ingredients={ingredients}
                handleRemoveIngredient={handleRemoveIngredient}
                handleIngredientObject={handleIngredientObject}
                handleIngredient={handleIngredient}
                fetchVariationType={fetchVariationType}
                errorList={errorList}
                errorStatus={errorStatus}
              />
            ) : (
              <RecipeStepTwo
                searchedValue={searchedValue}
                showRecipeView={showRecipeView}
                recipes={recipes}
                handleChangeSearchedValue={handleChangeSearchedValue}
                handleSelectOption={handleSelectOption}
                handleDeleteRecipe={handleDeleteRecipe}
                handleAddItem={handleAddItem}
                handleAddRecipeStep={handleAddRecipeStep}
                handleDeleteRecipeStep={handleDeleteRecipeStep}
                handleStepValue={handleStepValue}
                CookingVariation={CookingVariation}
                addSteps={addSteps}
                steps={steps}
                image={image}
                handleImage={handleImage}
                description={description}
                handleDescription={handleDescription}
                handleDeleteStep={handleDeleteStep}
                stepIndex={stepIndex}
                stepDeleteDialog={stepDeleteDialog}
                handleDeleteDialog={(data: boolean) =>
                  setStepDeleteDialog(data)
                }
                handleChangeStep={handleChangeStep}
                handleIndex={(data: number) => setStepIndex(data)}
                calory={calory}
                fat={fat}
                carb={carb}
                protein={protein}
                recipeNote={recipeNote}
                blockedForEdit={blockedForEdit}
                video={video}
                prep={prep}
                serving={serving}
                allergen={allergen}
                addinfo={addinfo}
                handlecalory={(e: any) => setCalory(e)}
                handlecarb={(e: any) => setcarb(e)}
                handlefat={(e: any) => setFat(e)}
                handleprotein={(e: any) => setProtein(e)}
                handleNote={(e: any) => setRecipeNote(e)}
                handlevideo={(e: any) => setVideo(e)}
                handleprep={(e: any) => setPrep(e)}
                handleserving={(e: any) => setServing(e)}
                handleallergenCheck={handleallergenCheck}
                handleaddionalinfocheck={handleaddionalinfocheck}
                onChangeCookingMenu={onChangeCookingMenu}
                cookingMenu={cookingMenu}
                errorList={errorList}
                errorStatus={errorStatus}
              />
            )}

            <div className="butonGroup">
              <div
                className="wizard-button"
                onClick={() => handleBackStepper(stepper - 1)}
              >
                Back
              </div>
              <div className="continue wizard-button" onClick={handleContinue}>
                Continue
              </div>
            </div>
          </div>
        </Spin>
      </Page>
    </React.Fragment>
  );
};

export default recipeWizard;
