/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  PaginationProps,
  Dropdown,
  Space,
  Spin,
  Tooltip,
  Select,
} from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  GridView,
  ListView,
  Pagination,
  Filter,
  Breadcrumb,
  Button,
  Dialog,
  SubDialog,
  ListCount,
  SearchBox,
  IconSwitcher,
  DeleteConfirmation,
  DonutChart,
  ResponseDialog,
} from '../../components';
import RecipeApiActions from '../../redux/middleware/recipe-api';
import { handleFloat, sortedData, sortNumber } from '../../helper/sortData';
import Dish from '../../assets/images/Dish.png';
import Fire from '../../assets/images/Fire.svg';
import More from '../../assets/images/More.png';
import Export from '../../assets/images/export.png';
import Attributes from '../../redux/middleware/attributes';
import Download from '../../assets/images/Download.svg';
import Delete from '../../assets/images/Delete.svg';
import Edit from '../../assets/images/Edit.png';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import Union from '../../assets/images/Union.png';
import Serving from '../../assets/images/Serving.png';
import SubMenu from '../../assets/images/SubMenu.svg';
import Clock from '../../assets/images/Clock.png';
import moment from 'moment';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import { useSelector } from 'react-redux';
import Utils from '../../redux/utils';
// import DonutChart from '../../components/DonutChart';
import './recipe.scss';
import { checkAuthentication } from '../../helper/checkAuthentication';
const itemsForNotVariation: MenuProps['items'] = [
  {
    key: '2',
    label: <div className="dotOption">Download</div>,
    icon: <img src={Download} width={15} />,
  },
  {
    key: '3',
    label: <div className="dotOption">Edit</div>,
    icon: <img src={Edit} />,
  },
  {
    type: 'divider',
  },
  {
    key: '4',
    label: <div className="dotOption deleteText">Delete</div>,
    icon: <img src={Delete} width={15} />,
  },
];

const Recipe = () => {
  const user = Utils.getCurrentUser();
  const [design, setDesign] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSubDialog, setOpenSubDialog] = useState(false);
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [dialogData, setDialogData] = useState({});
  const [total, setTotal] = useState(0);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [recipeCount, setRecipeCount] = useState(0);
  const [selectedRecipeId, setSelectedRecipeId] = useState('');
  const [loader, setLoader] = useState(false);
  const [selectedParentRecipeId, setSelectedParentRecipeId] = useState('');
  const [selectedRecipeStatus, setSelectedRecipeStatus] = useState(0);
  const [ingrdientCount, setIngredientCount] = useState(0);
  const PageDataLength = [6, 10, 20, 50];
  const navigate = useNavigate();
  const [id, setId] = useState('');
  const [pdf, setPdf] = useState('');
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [popUp, setPopUp] = useState<any>(null);
  const [search, setSearch] = useState('');
  const [selectedVariationData, setSelectedVariationData] = useState<any>(null);
  const [recipesData, setRecipesData] = useState<any>();
  const [maxCalorie, setMaxCalorie] = useState<number>();
  const [variationName, setVariationName] = useState('');
  const [recipeCalories, setRecipeCalories] = useState('');
  const [recipeServings, setRecipeServings] = useState('');
  const { currentBrand, currentShop } = useSelector((state: any) => state.Auth);
  const [allowRequest, setAllowRequest] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [currentBrandId] = useState(currentBrand?.brand_id);
  // eslint-disable-next-line prettier/prettier
  const [selectedVariationsName, setSelectedVariationsName] = useState<
    string | null
  >(null);
  var selectedVariationName = '';
  useEffect(() => {
    if (currentBrandId !== currentBrand?.brand_id) {
      setKeyword('');
      setAllowRequest(true);
    }
  }, [currentBrand, currentShop]);

  useEffect(() => {
    if (allowRequest) {
      pageData();
      setAllowRequest(false);
    }
  }, [allowRequest]);

  // React.useEffect(() => {
  //   pageData();
  // }, [currentBrand]);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <div className="dotOption">Add Variation</div>,
      icon: <img src={Union} className=" duplicateIcon" />,
    },
    {
      key: '2',
      label: (
        // <a href={pdf} download>
        <div className="dotOption">Download</div>
        // </a>
      ),
      icon: <img src={Download} width={15} />,
    },
    {
      key: '3',
      label: <div className="dotOption">Edit</div>,
      icon: <img src={Edit} />,
    },
    {
      type: 'divider',
    },
    {
      key: '4',
      label: <div className="dotOption deleteText">Delete</div>,
      icon: <img src={Delete} width={15} />,
    },
  ];
  const itemsForReadOnlyUsers: MenuProps['items'] = [
    {
      key: '2',
      label: (
        <a href={pdf} download>
          <div className="dotOption">Download</div>
        </a>
      ),
      icon: <img src={Download} width={15} />,
    },
  ];
  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);

    setSortData(() => arr);
  };

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };

  const setSelectedMenu = (recipe: any) => {
    setSelectedParentRecipeId(recipe?.parent_recipe_id);
    setSelectedRecipeId(recipe.recipe_id);
    setSelectedRecipeStatus(recipe?.status);
  };

  const columns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>RECIPE NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('recipe_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'recipe',
      key: 'recipe',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => {
        return (
          <div className="col1 font">
            <img
              src={record?.thumbnail ? record?.thumbnail : Dish}
              width="52px"
              height="52px"
              className="listView-image-align"
            />
            <div className="subCol1 font table-title1-hover alignment-left-column-values">
              <span
                className="col1Text font"
                onClick={() => {
                  handleingredient(record);
                }}
              >
                {/* {record?.recipe_name} */}
                {record?.recipe_name?.length > 22 ? (
                  <Tooltip
                    title={
                      <div>
                        <div className="tootipText">{record?.recipe_name}</div>
                      </div>
                    }
                  >
                    {record?.recipe_name?.substring(0, 22)}
                    {record?.recipe_name?.length > 22 ? <span>...</span> : null}
                  </Tooltip>
                ) : (
                  record?.recipe_name
                )}
              </span>
              <div className="col3 cellSpacing  common-flex-center calorie-serving-subrecipe-rd-gap">
                <div className="common-flex-center">
                  {' '}
                  <div></div>
                  <DonutChart
                    popUp={popUp}
                    setPopUp={setPopUp}
                    index={index}
                    data={record}
                    component={'listView'}
                  >
                    {record.is_verified ? (
                      <img
                        src={Fire}
                        width={15}
                        alt=""
                        className=" iconFireWhite IconsAlignment"
                      />
                    ) : (
                      <img
                        src={Fire}
                        width={15}
                        alt=""
                        className="subCol1Icon IconsAlignment"
                      />
                    )}
                  </DonutChart>
                  {record?.is_variation === 'yes' ? (
                    record?.variation_list?.length === 1 ? (
                      <div>
                        {handleFloat(record?.variation_list[0]?.calorie)}
                      </div>
                    ) : (
                      <div>
                        {record?.calorie
                          ? handleFloat(record.calorie)
                          : handleFloat(recipeCalories)}
                      </div>
                    )
                  ) : (
                    <div>{handleFloat(record?.calorie)}</div>
                  )}
                </div>
                <div className="tableCol col3 common-flex-center">
                  <Tooltip
                    title={
                      <div>
                        <div className="tooltipHead">Servings</div>
                        <div className="tootipText">
                          {record?.is_variation === 'yes' ? (
                            record?.variation_list?.length === 1 ? (
                              <div>
                                {record?.variation_list[0]?.servings} person
                                serving
                              </div>
                            ) : (
                              <div>
                                {record?.servings
                                  ? record.servings
                                  : recipeServings}{' '}
                                person serving
                              </div>
                            )
                          ) : (
                            <div>{record?.servings} person serving</div>
                          )}
                        </div>
                      </div>
                    }
                  >
                    <img src={Serving} alt="" className="icons" />
                  </Tooltip>
                  {record?.is_variation === 'yes' ? (
                    record?.variation_list?.length === 1 ? (
                      <div>{record?.variation_list[0]?.servings}</div>
                    ) : (
                      <div>
                        {record?.servings ? record.servings : recipeServings}
                      </div>
                    )
                  ) : (
                    <div>{record?.servings}</div>
                  )}
                </div>
                <div className="tableCol col3 common-flex-center">
                  <Tooltip
                    title={
                      <div>
                        <div className="tooltipHead">Sub recipe</div>
                        <div className="tootipText">
                          Total {record?.subrecipe_count ?? 0} sub recipes
                        </div>
                      </div>
                    }
                  >
                    <img src={SubMenu} width={15} alt="" className="icons" />
                  </Tooltip>
                  {record?.subrecipe_count ?? 0}
                </div>
                {record?.variation_list &&
                  record?.variation_list?.length !== 0 && (
                    <>
                      <div className="col3 cellSpacing">
                        <Select
                          onChange={(e) => {
                            handleChange(e, index, record?.variation_list);
                            const selectedId = e;
                            const selectedVariation =
                              record?.variation_list?.find((variation: any) => {
                                if (variation.variation_id === selectedId) {
                                  selectedVariationName = variation.variation;
                                  setSelectedVariationsName(
                                    variation.variation
                                  );
                                  // Todo: that insert a flag inside record, flag contain variation id/name
                                }
                                return variation.variation_id === selectedId;
                              });
                            if (
                              selectedVariationName ===
                              selectedVariation?.variation
                            ) {
                              setRecipeCalories(selectedVariation?.calorie);
                              setRecipeServings(selectedVariation?.servings);
                            }
                          }}
                          defaultValue={
                            record?.variation ||
                            record?.variation_list?.[0].variation
                          }
                          style={{ width: 120, marginTop: -5 }}
                          bordered={false}
                          // value={selectedVariationsName}
                        >
                          {record?.variation_list?.map(
                            (data: any, innerindex: number) => (
                              <Select.Option
                                value={data?.variation_id}
                                key={innerindex}
                              >
                                {data?.variation}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      </div>
                    </>
                  )}
                {/* <div className="tableCol col3 common-flex-center">
                  <Tooltip
                    title={
                      <div>
                        {moment(UTCtoLocal(record?.created_at)).format(
                          'DD-MM-YYYY HH:mm:ss'
                        )}
                      </div>
                    }
                    className="common-flex-center"
                  >
                    <img src={Clock} alt="" className="icons" />
                  </Tooltip>
                </div> */}
              </div>
              <div className="column-created-on-field col3 ">
                <Tooltip
                  title={
                    <div>
                      {moment(UTCtoLocal(record?.created_at)).format(
                        'DD-MM-YYYY HH:mm:ss'
                      )}
                    </div>
                  }
                  className="common-flex-center"
                >
                  {/* <img src={Clock} alt="" className="icons" /> */}
                </Tooltip>
                <div>
                  {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY')}
                </div>
              </div>
            </div>
          </div>
        );
      },
    },

    {
      title: () => (
        <div className="tableTitle">
          <span>CATEGORY</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('menu_category', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col2">
          <Tooltip
            title={
              <div>
                <div className="tooltipHead">Category</div>
                <div className="tootipText">{record?.menu_category}</div>
              </div>
            }
            className="cursor"
          >
            {record?.menu_category?.substring(0, 22)}
            {record?.menu_category?.length > 22 ? <span>...</span> : null}
          </Tooltip>
        </div>
      ),
    },
    // {
    //   title: () => (
    //     <div className="tableTitle">
    //        <span>CREATED</span>
    //       <img
    //         src={UpDownArrow}
    //         className="titleArrow"
    //         onClick={() => {
    //           handleSort('', 'string');
    //           setAscend(!ascend);
    //         }}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'category',
    //   key: 'category',
    //   responsive: ['md'],
    //   render: (data: any, record: any, index: number) =>
    //     record?.variation_list?.length !== 0 ? (
    //       <div className="col3 cellSpacing">
    //         <Select
    //           onChange={(e) => {
    //             handleChange(e, index, record?.variation_list);
    //           }}
    //           defaultValue={
    //             record?.variation || record?.variation_list?.[0].variation
    //           }
    //           style={{ width: 120, marginTop: -5 }}
    //           bordered={false}
    //           // value={record?.variation || record?.variation_list?.[0].variation}
    //         >
    //           {record?.variation_list?.map((data: any, innerindex: number) => (
    //             <Select.Option value={data?.variation_id} key={innerindex}>
    //               {data.variation}
    //             </Select.Option>
    //           ))}
    //         </Select>
    //       </div>
    //     ) : (
    //       ''
    //     ),
    // },
    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>SERVINGS</span>

    //       <img
    //         src={UpDownArrow}
    //         className="titleArrow"
    //         onClick={() => {
    //           handleSort('servings', 'number');
    //           setAscend(!ascend);
    //         }}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'servings',
    //   key: 'servings',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => (
    //     <div className="col3">{handleFloat(record?.servings)}</div>
    //   ),
    // },
    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>SUB RECIPE</span>

    //       <img
    //         src={UpDownArrow}
    //         className="titleArrow"
    //         onClick={() => {
    //           handleSort('subrecipe_count', 'number');
    //           setAscend(!ascend);
    //         }}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'subrecipe_count',
    //   key: 'subrecipe_count',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => (
    //     <div className="col3">{handleFloat(record?.subrecipe_count)}</div>
    //   ),
    // },
    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>CREATED</span>

    //       <img
    //         src={UpDownArrow}
    //         className="titleArrow"
    //         onClick={() => {
    //           handleSort('created_at', 'string');
    //           setAscend(!ascend);
    //         }}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'created_at',
    //   key: 'created_at',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => (
    //     <div className="">
    //       {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY HH:mm:ss')}
    //     </div>
    //   ),
    // },
    {
      title: () => (
        <div className="tableTitle">
          <span>STATUS</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('status', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div
          className={
            record?.status === 1
              ? 'statusContainerActive'
              : 'statusContainerNonActive'
          }
        >
          <div className="status">
            {record?.status == 1 ? 'Active' : 'Non-active'}
          </div>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <Dropdown
            menu={
              record?.is_variation === 'no'
                ? menuPropsNotVariation
                : DropDownmenuProps
            }
            trigger={['click']}
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
          >
            <a onClick={() => setSelectedMenu(record)}>
              <Space>
                <div className="dots">
                  <img src={More} />
                </div>
              </Space>
            </a>
          </Dropdown>
        );
      },
    },
  ];
  useEffect(() => {
    checkAuthentication(user, navigate);
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'Recipe'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, currentBrand]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const handleingredient = async (data: any) => {
    setVariationName(data?.variation);
    setVariationName(
      data?.is_variation == 'yes'
        ? data?.variation?.length
          ? data?.variation
          : data?.variation_list[0]?.variation
        : ''
    );

    const brand = await Utils.getCurrentBrand();
    const details = await RecipeApiActions.getIngredientDetail(
      data?.recipe_id,
      currentBrand?.brand_id || brand?.brand_id
    );
    setDialogData(details);
    setOpenDialog(true);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleChange = (data: any, index: number, list: any) => {
    const dataObj: any = list.find((val: any) => {
      return val.variation_id === data;
    });
    selectedVariationName = dataObj?.variation || null;
    setSelectedVariationsName(dataObj?.variation || null);
    setSelectedVariationData({
      ingredients: dataObj?.ingredients || null,
    });
    // if (dataObj?.variation_id && selectedVariationName === dataObj?.variation) {
    //   setRecipeCalories(dataObj?.calorie);
    //   setRecipeServings(dataObj?.servings);
    // }
    handledata(dataObj, index);
  };
  const handledata = (data: any, index: number) => {
    let items: any = sortData;
    items[index].recipe_id = data[0]?.recipe_id;
    items[index].servings = data[0]?.servings;
    items[index].created_at = data[0]?.created_at;
    items[index].final_price = data[0]?.final_price;
    items[index].calorie = data[0]?.calorie;
    items[index].carb = data[0]?.carb;
    items[index].fat = data[0]?.fat;
    items[index].protein = data[0]?.protein;
    items[index].allergens_id = data[0]?.allergens_id;
    items[index].variation = data[0]?.variation;
    setSortData([...items]);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1:
        navigate('wizard', {
          state: {
            variationType: true,
            id: selectedRecipeId,
            parentId: selectedParentRecipeId,
          },
        });
        break;
      case 2:
        {
          setLoader(true);
          // const data = await RecipeApiActions.downloadRecipe(selectedRecipeId);
          const data = recipesData;
          if (selectedRecipeId && data && data.length > 0) {
            const selectedRecipe = data.find(
              (recipe: { recipe_id: string }) =>
                recipe.recipe_id === selectedRecipeId
            );
            if (selectedRecipe && selectedRecipe?.pdf_link) {
              const pdfLink = selectedRecipe?.pdf_link;
              setPdf(pdfLink);
              setLoader(false);
              openInNewTab(pdfLink);
            } else {
              setLoader(false);
            }
          } else {
            setLoader(false);
          }
          // fetch('document.pdf').then((response) => {
          //   response.blob().then((blob) => {
          //     // Creating new object of PDF file
          //     const fileURL = window.URL.createObjectURL(blob);
          //     // Setting various property values
          //     let alink = document.createElement('a');
          //     alink.href = fileURL;
          //     alink.download = 'index.html';
          //     alink.click();
          //   });
          // });
        }
        break;
      case 3:
        if (selectedRecipeStatus) {
          let responseDialogData = {
            type: 'error',
            messageArray: [
              `This Recipe is part of active menu, Kindly change its status to inactive before Updating it`,
            ],
            OnClick: () => {},
          };
          ResponseDialog(responseDialogData);
        } else {
          navigate('wizard', {
            state: {
              update: true,
              id: selectedRecipeId,
              parentId: selectedParentRecipeId,
              variationType: false,
              status: selectedRecipeStatus,
            },
          });
        }
        break;

      case 4:
        setOpenDeleteDialog(true);

        break;
      default:
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteContinue = async () => {
    setOpenDeleteDialog(false);
    const response =
      selectedRecipeId.length &&
      (await RecipeApiActions.delRecipe(selectedRecipeId));
    if (response?.success === true) {
      pageData();
      // setRecipeLoader(false);
    } else if (response == undefined || response?.success === false) {
      // setRecipeLoader(false);
      let responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: ['Error!', `${response?.message}`],
      };
      ResponseDialog(responseDialogData);
    }
  };
  const DropDownmenuProps = {
    items:
      permissionStatus && permissionStatus === 2
        ? items
        : itemsForReadOnlyUsers,
    onClick: handleDropDownClick,
  };
  const menuPropsNotVariation = {
    items:
      permissionStatus && permissionStatus === 2
        ? itemsForNotVariation
        : itemsForReadOnlyUsers,
    onClick: handleDropDownClick,
  };

  const pageData = async () => {
    const brand = await Utils.getCurrentBrand();
    setRecipeLoader(true);
    const recipeData = await RecipeApiActions.getRecipes(
      dataLength,
      current,
      currentBrand?.brand_id || brand?.brand_id,
      keyword
    );
    if (recipeData == undefined || !recipeData?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
      setMaxCalorie(0);
    } else {
      const items = recipeData?.data?.items || [];
      setSortData(items);
      setRecipesData(items);
      setRecipeCount(recipeData?.data?.recipe_count);
      setIngredientCount(recipeData?.data?.ingredients_count);
      setTotal(recipeData?.data?.pagination?.total);
      setMaxCalorie(recipeData?.data?.max_calorie);
    }
    setRecipeLoader(false);
  };

  const pageDataFilter = async (filters: any) => {
    setRecipeLoader(true);
    const recipeData = await RecipeApiActions.recipesFilters(
      dataLength,
      current,
      filters,
      search
    );

    if (recipeData == undefined || !recipeData?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(recipeData?.data?.recipe_count);
      setIngredientCount(recipeData?.data?.ingredients_count);
      setTotal(recipeData?.data?.pagination?.total);
      setSortData(recipeData?.data?.items);
    }

    setRecipeLoader(false);
  };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', {
          state: { update: false, id: '', variationType: false },
        });
      }}
      shape="round"
      size="middle"
    />
  );

  const onSearch = async (keyword: string) => {
    setRecipeLoader(true);
    // if (keyword) {
    //   const results = sortData.filter(
    //     (obj: any) =>
    //       obj.recipe_name.toLowerCase().includes(keyword.toLowerCase()) ||
    //       obj.menu_category.toLowerCase().includes(keyword.toLowerCase()) ||
    //       obj.created_at?.includes(keyword)
    //   );
    //   setSortData(results);
    // } else {
    //   setSortData(recipes);
    // }
    setSearch(keyword);
    const searchResultofRecipes = await RecipeApiActions.searchRecipe(
      dataLength,
      current,
      keyword
    );
    if (searchResultofRecipes == undefined || !searchResultofRecipes?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(searchResultofRecipes?.data?.recipe_count);
      setIngredientCount(searchResultofRecipes?.data?.ingredients_count);
      setTotal(searchResultofRecipes?.data?.pagination?.total);
      setSortData(searchResultofRecipes?.data?.items);
    }

    setRecipeLoader(false);
  };

  const handleSubDialogOpen = async (id: any) => {
    const brand = await Utils.getCurrentBrand();
    const details = await SubRecipeActions.getIngredientDetail(
      id,
      currentBrand?.brand_id || brand?.brand_id
    );
    setDialogData(details);
    setOpenDialog(true);
  };

  const handleCSV = async () => {
    setLoader(true);
    const response = await Attributes.exportCSV('Recipes');
    setLoader(false);
    const { data } = response;
    window.open(data?.url);
  };

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleCookBook = async () => {
    setLoader(true);
    const response = await RecipeApiActions.exportCookBook();
    const { success, data } = response;
    if (success) {
      openInNewTab(data);
    }
    setLoader(false);
  };

  return (
    <div className="recipePage">
      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={
          <>
            Are you sure want to delete recipe?
            <br />
            <i>
              Note: This Delete action will automatically reflect on the
              Integrated platform (e.g. Foodics) as well
            </i>
          </>
        }
      />
      <Dialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleSubDialog={(id: any) => {
          setOpenDialog(false);
          setOpenSubDialog(true);
          setId(id);
        }}
        data={dialogData}
        component={'recipe'}
        printApi={RecipeApiActions}
        variationName={variationName}
      />
      <SubDialog
        open={openSubDialog}
        data={id}
        handleSubDialog={() => {
          setOpenSubDialog(false);
          setOpenDialog(true);
        }}
        handleCloseAll={() => {
          setOpenSubDialog(false);
          setOpenDialog(false);
        }}
        component={'recipe'}
      />

      <Breadcrumb
        heading="Recipes"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <Spin spinning={loader} size="large">
        <Page title="Dashboard">
          <Row className="listingTopFilter">
            <Col md={24} lg={4}>
              <ListCount
                count1={recipeCount}
                title1="Total Recipes"
                count2={ingrdientCount}
                title2="Total Ingredients"
              />
            </Col>
            <Col md={24} lg={20}>
              <div className="search-wrapper">
                <SearchBox
                  keyword={keyword}
                  setKeyword={setKeyword}
                  action={onSearch}
                  placeholderText="Search..."
                />
                <div
                  className="exportButtonForCookBook"
                  onClick={handleCookBook}
                >
                  <img src={Export} alt="" />
                  <div className="exportText"> Export SOP</div>
                </div>
                <div className="exportButton" onClick={handleCSV}>
                  <img src={Export} alt="" />
                  <div className="exportText"> Export CSV</div>
                </div>

                <IconSwitcher listViewLayout={design} setListView={setDesign} />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={8}>
              <Filter
                action={pageDataFilter}
                apiCall={RecipeApiActions.getRecipesFilters}
                maximumCalorie={maxCalorie}
              />
            </Col>
            <Col lg={18} md={16}>
              {!design && !recipeLoader ? (
                <GridView
                  handledata={handledata}
                  DropDownmenuProps={DropDownmenuProps}
                  menuPropsNotVariation={menuPropsNotVariation}
                  setSelectedMenu={setSelectedMenu}
                  dataSource={sortData}
                  component={'recipe'}
                  handleDialog={''}
                  UTCtoLocal={UTCtoLocal}
                  handleOpen={(data: any) => {
                    handleingredient(data);
                  }}
                />
              ) : (
                !design && (
                  <div className="loader-wrapper">
                    <Spin spinning={recipeLoader} className="recipeLoader" />
                  </div>
                )
              )}

              {design && !recipeLoader ? (
                <ListView
                  limit={dataLength}
                  component="recipe"
                  items={items}
                  dataSource={[...sortData]}
                  columns={columns}
                  handleOpen={handleSubDialogOpen}
                  onChange={handleChange}
                />
              ) : (
                design && (
                  <div className="loader-wrapper">
                    <Spin spinning={recipeLoader} className="recipeLoader" />
                  </div>
                )
              )}
              <Pagination
                total={total}
                menuProps={menuProps}
                onChange={onChange}
                current={current}
                dataLength={dataLength >= total ? total : dataLength}
                handleLength={(data: number) => setDataLength(data)}
              />
            </Col>
          </Row>
        </Page>
      </Spin>
    </div>
  );
};

export default Recipe;
