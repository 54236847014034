import React, { useEffect, useRef, useState } from 'react';
import { Input, Modal, Spin } from 'antd';
import Attributes from '../../redux/middleware/attributes';
import Delete from '../../assets/images/Delete.svg';

import './style.scss';
import { RcFile } from 'antd/lib/upload';
import { handleFloat } from '../../helper/sortData';

type Props = {
  image: any;
  description: any;
  steps: any;
  prep: any;
  calory: any;
  carb: any;
  fat: any;
  protein: any;
  stepDeleteDialog: any;
  handleIndex: any;
  handleImage: any;
  handleDescription: any;
  addSteps: any;
  handleChangeStep: any;
  handleCalory: any;
  handlecarb: any;
  handleFat: any;
  handleProtein: any;
  handleallergenCheck: any;
  handleaddionalinfocheck: any;
  handleDeleteStep: any;
  handleDeleteDialog: any;
  handlePrep: any;
  additonal_info: any;
  allergens: any;
  component: String;
  serving: any;
  handleServing: any;
  recipeNote: any;
  handleRecipeNote: any;
  errorList?: any;
  errorStatus?: any;
};

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const SustainabilityStepTwo = (props: Props) => {
  const [spinner, setSpinner] = useState(true);
  const [allergenData, setAlergenData] = useState<any[]>([]);
  const [allergenSelected, setAllergenSelected] = useState<number[]>([]);
  const [attributes, setAttributes] = useState<any[]>([]);
  const fileRef = React.useRef<HTMLInputElement>(null);

  let {
    image,
    description,
    steps,
    prep,
    calory,
    carb,
    fat,
    protein,
    stepDeleteDialog,
    // handleIndex,
    handleImage,
    handleDescription,
    addSteps,
    handleChangeStep,
    handleCalory,
    handlecarb,
    handleFat,
    handleProtein,
    handleallergenCheck,
    handleaddionalinfocheck,
    handleDeleteStep,
    handleDeleteDialog,
    handlePrep,
    additonal_info,
    allergens,
    component,
    serving,
    handleServing,
    recipeNote,
    handleRecipeNote,
    // errorList,
    // errorStatus,
  } = props;

  useEffect(() => {
    fetchAllergen();
    fetchAdditionalData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (attributes?.length && allergenData?.length) setSpinner(false);
  }, [allergenData, attributes]);

  const fetchAdditionalData = async () => {
    const data = await Attributes.getAdditionalInfo();
    setAttributes(data?.data);
  };

  const inputRefs = useRef<any>(steps?.map(() => React.createRef()));

  const handleKeyDown = (e: any, index: number) => {
    if (e.key === 'Enter') {
      // Set focus to next text input
      addSteps();
      setTimeout(() => inputRefs.current[index + 1].focus(), 200);
      // inputRefs.current[index + 1].focus();
    }
  };

  const fetchAllergen = async () => {
    const data = await Attributes.getAllergens();
    setAlergenData(data?.data);
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    val: any
  ) => {
    let updatedSelection: any[] = [];
    updatedSelection = allergenSelected.includes(val.id)
      ? allergenSelected.filter((id: any) => id !== val.id)
      : [...allergenSelected, val.id];
    setAllergenSelected(updatedSelection);
    handleallergenCheck(e, val);
  };

  const { TextArea } = Input;

  return (
    <>
      <Modal
        title={<div className="title font">Confirmation</div>}
        centered
        open={stepDeleteDialog}
        onCancel={() => handleDeleteDialog(false)}
        width={480}
        footer={null}
        className="DialogHeight"
      >
        <div className="deleteDialogText">
          Are you sure want to delete that step?
        </div>
        <div className="butonGroupDialog">
          <div
            className="wizard-button"
            onClick={() => handleDeleteDialog(false)}
          >
            Cancel
          </div>
          <div className="continue wizard-button" onClick={handleDeleteStep}>
            Continue
          </div>
        </div>
      </Modal>
      <div className="SustainabilityStepTwo-MainHead">Ingredient Detail</div>
      <div className="SustainabilityStepTwo">
        <div className="SubRecipeWizardLabel">Upload Image</div>
      </div>
      {image ? (
        <div className="SubRecipeImageUpload">
          <div className="SubRecipeImageWrapper">
            <img src={image} alt="" className="SubRecipeImageHeight" />
            <div className="deleteButton" onClick={() => handleImage('', {})}>
              <img src={Delete} width={15} alt="" />
            </div>
          </div>
        </div>
      ) : (
        <div className="SubRecipeFormgroup formgroupStepOne">
          <div className="SubRecipe-label-wrapper">
            <label>
              <p className="ant-upload-text ">Drop your image here or browse</p>
              <p className="ant-upload-hint">
                Minimum file 2 MB (.png,.jpg,.jpeg)
              </p>
              <input
                ref={fileRef}
                type="file"
                accept=".png,.jpg,.jpeg"
                onChange={async (e: any) => {
                  const response = await getBase64(e.currentTarget.files[0]);
                  if (response) handleImage(response, e);
                }}
              />
            </label>
          </div>
        </div>
      )}
      <div className="SustainabilityStepTwo ">
        <div className="SubRecipeWizardLabel">Description</div>
        <TextArea
          rows={4}
          className="text-Area"
          placeholder="Input description here"
          autoSize={{ minRows: 5, maxRows: 6 }}
          value={description}
          onChange={(e) => handleDescription(e.target.value)}
        />
      </div>
      <div className="SustainabilityStepTwo-MainHead">Cooking Method</div>
      <div className="SustainabilityStepTwo ">
        <div className="SubRecipeWizardLabel">Steps</div>

        {steps?.map((data: any, index: number) => (
          <div className="SubRecipe-stepTwo-input" key={index}>
            <div>{index + 1}</div>
            <Input
              placeholder="Enter field name"
              value={data}
              onChange={(e: any) => handleChangeStep(e.target.value, index)}
              onKeyDown={(e: any) => handleKeyDown(e, index)}
              ref={(el) => (inputRefs.current[index] = el)}
            />

            <img
              src={Delete}
              width={15}
              alt=""
              onClick={() => {
                // handleIndex(index);
                // handleDeleteDialog(true);
                handleDeleteStep(index);
              }}
            />
          </div>
        ))}
        {/* {errorStatus && (
          <div
            className="subRecipeErrorMessages"
            style={{ margin: ' 4px 5px 5px 85px' }}
          >
            {errorList['recipe_name']}
          </div>
        )} */}
        <div className="SubRecipeStepAddButton" onClick={addSteps}>
          + Add New
        </div>
      </div>
      <div className="SustainabilityStepTwo SubRecipeTextArea">
        <div className="SubRecipeWizardLabel">Recipe Notes</div>

        <TextArea
          rows={4}
          className="text-Area"
          placeholder="Input recipe notes here"
          autoSize={{ minRows: 5, maxRows: 5 }}
          value={recipeNote}
          onChange={(e) => handleRecipeNote(e.target.value)}
        />
      </div>
      <div
        className={
          component == 'productionRecipe'
            ? 'productionRecipeStep2SubHead'
            : 'SustainabilityStepTwo-Head'
        }
      >
        Advanced
      </div>

      {component == 'productionRecipe' ? (
        <div
          className={
            component == 'productionRecipe'
              ? 'productionPracticeContainer PracticeContainerJustify'
              : 'PracticeContainer PracticeContainerJustify'
          }
        >
          <div className="subContainerPractice stepThreeTextArea">
            <div className="PracticeLabel ">Prep Time (Mins)</div>
            <Input
              placeholder="Enter prep time"
              value={handleFloat(prep)}
              type="text"
              onChange={(e) => {
                const value = e.target.value;
                if (value === '') {
                  handlePrep('');
                } else {
                  const floatValue = parseFloat(value);
                  if (!isNaN(floatValue)) {
                    handlePrep(floatValue);
                  }
                }
              }}
            />
          </div>

          <div className="subContainerPractice stepThreeTextArea">
            <div className="PracticeLabel">
              Serving <span className="sterik">*</span>
            </div>
            <Input
              placeholder="Enter serving"
              value={serving}
              type="number"
              onChange={(e) => handleServing(e.target.value)}
            />
          </div>
        </div>
      ) : (
        <div className="SustainabilityStepTwo ">
          <div className="SubRecipeWizardLabel">Prep Time (Mins)</div>
          <Input
            placeholder="Enter prep time"
            value={handleFloat(prep)}
            type="text"
            onChange={(e) => {
              const value = e.target.value;
              if (value === '') {
                handlePrep('');
              } else {
                const floatValue = parseFloat(value);
                if (!isNaN(floatValue)) {
                  handlePrep(floatValue);
                }
              }
            }}
          />
          {/* {errorStatus && (
            <div className="subRecipeErrorMessages">
              {errorList['recipe_name']}
            </div>
          )} */}
        </div>
      )}

      <div
        className={
          component == 'productionRecipe'
            ? 'productionWizardSubHead'
            : 'SubRecipeWizard-subHead'
        }
      >
        Nutritions Facts
      </div>
      <div className="SubRecipeWizardSubContainer">
        <div className="SubRecipeWizardadditonal">
          <div>Calories</div>
          <div>Carbs</div>
          <div>Fat</div>
          <div>Protein</div>
        </div>
        <div className="SubRecipeUnitContainer">
          <div className="SubRecipeBorderContainer">
            <div className="SubRecipeUnitSubContainer">
              <input
                className="gramValue"
                placeholder="0"
                type={'number'}
                disabled={true}
                value={calory == 0 ? '' : calory}
                onChange={(e) => handleCalory(e.target.value)}
              />
              <div className="unitColor">kcal</div>
            </div>
            {/* {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['recipe_name']}
              </div>
            )} */}
          </div>
          <div className="borderContainer">
            <div className="unitSubContainer">
              <input
                className="gramValue"
                placeholder="0"
                type={'number'}
                disabled={true}
                value={carb == 0 ? '' : carb}
                onChange={(e) => handlecarb(e.target.value)}
              />
              <div className="unitColor">g</div>
            </div>
          </div>
          <div className="borderContainer">
            <div className="unitSubContainer">
              <input
                className="gramValue"
                placeholder="0"
                type={'number'}
                disabled={true}
                value={fat == 0 ? '' : fat}
                onChange={(e) => handleFat(e.target.value)}
              />
              <div className="unitColor">g</div>
            </div>
          </div>
          <div className="borderRemove">
            <div className="unitSubContainer ">
              <input
                className="gramValue"
                placeholder="0"
                type={'number'}
                disabled={true}
                value={protein == 0 ? '' : protein}
                onChange={(e) => handleProtein(e.target.value)}
              />
              <div className="unitColor">g</div>
            </div>
          </div>
        </div>
      </div>
      {spinner ? (
        <Spin className="loadIngredient" />
      ) : (
        <div className="wizardLabelAdvance">
          <div className="SustainabilityStepTwo allergens">Allergens</div>
          <ul className="allergens-content">
            {allergenData.map((val, index) => {
              const isSelected = allergens.some(
                (data: any) => data?.id === val?.id
              );
              return (
                <li key={index}>
                  <input
                    type="checkbox"
                    checked={isSelected || allergenSelected.includes(val?.id)}
                    disabled={isSelected && !allergenSelected.includes(val?.id)}
                    onChange={(e) => handleCheckboxChange(e, val)}
                    className="checkboxIngredient"
                  />
                  <label>{val?.name}</label>
                </li>
              );
            })}
          </ul>
          {/* {errorStatus && (
            <div
              className="subRecipeErrorMessages"
              style={{ margin: '-15px 5px' }}
            >
              {errorList['recipe_name']}
            </div>
          )} */}
        </div>
      )}
      {spinner ? (
        <Spin />
      ) : (
        <div className="SustainabilityStepTwo">
          <div className=" wizardLabelAdvance allergens">Additional Info</div>
          <ul className="allergens-content">
            {attributes.map((val, index) => {
              return (
                <li key={index}>
                  <input
                    type="checkbox"
                    onChange={(e) => handleaddionalinfocheck(e, val)}
                    className="checkboxIngredient"
                    checked={
                      additonal_info.filter((data: any) => data?.id === val?.id)
                        .length
                        ? true
                        : false
                    }
                  />
                  <label className="addtionalAttributeLabel">
                    {val?.attribute_name}
                  </label>
                </li>
              );
            })}
            {/* {errorStatus && (
              <div
                className="subRecipeErrorMessages"
                style={{ margin: '30px 5px' }}
              >
                {errorList['recipe_name']}
              </div>
            )} */}
          </ul>
        </div>
      )}
    </>
  );
};

export default SustainabilityStepTwo;
