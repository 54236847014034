import './theme';
import ReactDOM from 'react-dom';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import App from './routes';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <App />
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
);
