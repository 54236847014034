/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined, CheckCircleOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  PaginationProps,
  Dropdown,
  Space,
  Spin,
  Tooltip,
  Popover,
  Button,
} from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  GridView,
  ListView,
  Pagination,
  Filter,
  Breadcrumb,
  // Button,
  Dialog,
  SubDialog,
  ListCount,
  SearchBox,
  IconSwitcher,
  DeleteConfirmation,
  IngendientListDialog,
  ResponseDialog,
} from '../../components';
import RecipeActions from '../../redux/middleware/recipe';
import Attributes from '../../redux/middleware/attributes';
import { handleFloat, sortedData, sortNumber } from '../../helper/sortData';
import Fire from '../../assets/images/Fire.svg';
import More from '../../assets/images/More.png';
import Download from '../../assets/images/Download.svg';
import Delete from '../../assets/images/Delete.svg';
import Edit from '../../assets/images/Edit.png';
import Export from '../../assets/images/export.png';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import Serving from '../../assets/images/Serving.png';
import SubMenu from '../../assets/images/SubMenu.svg';
import Clock from '../../assets/images/Clock.png';
import moment from 'moment';
import './styles.scss';
import { useSelector } from 'react-redux';
import Utils from '../../redux/utils';
const PageDataLength = [6, 10, 20, 50];
import DonutChart from '../../components/DonutChart';
import { checkAuthentication } from '../../helper/checkAuthentication';
import RecipeApiActions from '../../redux/middleware/recipe-api';
import AuthActions from '../../redux/middleware/auth';
const Dashboard = () => {
  const user = Utils.getCurrentUser();
  const [design, setDesign] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSubDialog, setOpenSubDialog] = useState(false);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [recipes, setRecipes] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [dialogData, setDialogData] = useState<any>({});
  const [total, setTotal] = useState(0);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [recipeCount, setRecipeCount] = useState(0);
  const [ingrdientCount, setIngredientCount] = useState(0);
  const [id, setId] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [allowRequest, setAllowRequest] = useState(false);
  const { currentShop } = useSelector((state: any) => state.Auth);
  const [keyword, setKeyword] = useState('');
  const [dialog, setDialog] = useState(false);
  const [data, setData] = useState<any>({});
  const [popUp, setPopUp] = useState<any>(null);
  const [search, setSearch] = useState('');
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [errorList, setErrorList] = useState<any>({});
  const [loader, setLoader] = useState(false);
  const { currentBrand } = useSelector((state: any) => state.Auth);
  const [currentBrandId] = useState(currentBrand?.brand_id);
  const [selectedRecipe, setSelectedRecipe] = useState(null as any);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <div className="dotOption">Approval</div>,
      icon: <CheckCircleOutlined className="inventory-transfer-iconDropdown" />,
    },
    {
      key: '2',
      label: <div className="dotOption">Download</div>,
      icon: <img src={Download} width={15} />,
    },
    {
      key: '3',
      label: <div className="dotOption">Edit</div>,
      icon: <img src={Edit} />,
    },
    {
      type: 'divider',
    },
    {
      key: '4',
      label: <div className="dotOption deleteText">Delete</div>,
      icon: <img src={Delete} width={15} />,
    },
  ];

  const itemsForRD: MenuProps['items'] = [
    {
      key: '1',
      label: <div className="dotOption">Recipe</div>,
    },
    {
      key: '2',
      label: <div className="dotOption">Sub Recipe</div>,
    },
  ];

  const itemsForRDExport: MenuProps['items'] = [
    {
      key: '0',
      label: <div className="dotOption">All Recipes</div>,
    },
    {
      key: '1',
      label: <div className="dotOption">Recipe</div>,
    },
    {
      key: '2',
      label: <div className="dotOption">Sub Recipe</div>,
    },
  ];

  const itemsForReadOnlyUsers: MenuProps['items'] = [
    {
      key: '2',
      label: <div className="dotOption">Download</div>,
      icon: <img src={Download} width={15} />,
    },
    {
      type: 'divider',
    },
    {
      key: '4',
      label: <div className="dotOption deleteText">Delete</div>,
      icon: <img src={Delete} width={15} />,
    },
  ];

  const navigate = useNavigate();

  const handleAddNewType: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    let type = 0;
    switch (option) {
      case 1:
        type = 1;
        break;
      case 2:
        type = 2;
        break;
      default:
    }
    onAddNew(type);
  };

  const handleExportCSV: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1:
        handleCSV('Recipes');
        break;
      case 2:
        handleCSV('SubRecipes');
        break;
      default:
        handleCSV('Recipes,SubRecipes');
    }
  };

  const [selectedRecipeId, setSelectedRecipeId] = useState('');
  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);
    setSortData(() => arr);
  };
  const setSelectedMenu = (recipe: any) => {
    setDialogData(recipe);
    setSelectedRecipe(recipe);
    setSelectedRecipeId(recipe.rb_id);
  };

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };

  const handleClose = () => {
    setErrorList({});
    setErrorStatus(false);
    setDialog(false);
  };
  const columns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>RECIPE NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('recipe_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'recipe',
      key: 'recipe',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => {
        return (
          <div className="col1 font">
            <img
              src={record?.thumbnail}
              width="52px"
              height="52px"
              className="listView-image-align"
            />
            <div className="subCol1 font">
              <div
                className="col1Text font table-title1-hover alignment-left-column-values"
                onClick={() => {
                  handleingredient(record);
                }}
              >
                {/* {record?.recipe_name} */}
                {record?.recipe_name?.length > 22 ? (
                  <Tooltip
                    title={
                      <div>
                        <div className="tootipText">{record?.recipe_name}</div>
                      </div>
                    }
                  >
                    {record?.recipe_name?.substring(0, 22)}
                    {record?.recipe_name?.length > 22 ? <span>...</span> : null}
                  </Tooltip>
                ) : (
                  record?.recipe_name
                )}
              </div>
              <div className="col3 cellSpacing  common-flex-center calorie-serving-subrecipe-rd-gap">
                <div className="common-flex-center">
                  {' '}
                  <DonutChart
                    popUp={popUp}
                    setPopUp={setPopUp}
                    index={index}
                    data={record}
                    component={'listView'}
                  >
                    {record.is_verified ? (
                      <img
                        src={Fire}
                        width={15}
                        alt=""
                        className=" iconFireWhite IconsAlignment"
                      />
                    ) : (
                      <img
                        src={Fire}
                        width={15}
                        alt=""
                        className="subCol1Icon IconsAlignment"
                      />
                    )}
                  </DonutChart>
                  {handleFloat(record?.calorie)}
                </div>
                <div className="tableCol col3 common-flex-center">
                  <Tooltip
                    title={
                      <div>
                        <div className="tooltipHead">Servings</div>
                        <div className="tootipText">
                          {record?.servings} person serving
                        </div>
                      </div>
                    }
                  >
                    <img src={Serving} alt="" className="icons" />
                  </Tooltip>
                  {record?.servings}
                </div>
                <div className="tableCol col3 common-flex-center">
                  <Tooltip
                    title={
                      <div>
                        <div className="tooltipHead">Sub recipe</div>
                        <div className="tootipText">
                          Total {record?.sub_recipe_items ?? 0} sub recipes
                        </div>
                      </div>
                    }
                  >
                    <img src={SubMenu} width={15} alt="" className="icons" />
                  </Tooltip>
                  {record?.sub_recipe_items ?? 0}
                </div>
                {/* <div className="tableCol col3 common-flex-center">
                  <Tooltip
                    title={
                      <div>
                        {moment(UTCtoLocal(record?.created_at)).format(
                          'DD-MM-YYYY HH:mm:ss'
                        )}
                      </div>
                    }
                    className="common-flex-center"
                  >
                    <img src={Clock} alt="" className="icons" />
                  </Tooltip>
                </div> */}
              </div>
              <div className="column-created-on-field col3 ">
                <Tooltip
                  title={
                    <div>
                      {moment(UTCtoLocal(record?.created_at)).format(
                        'DD-MM-YYYY HH:mm:ss'
                      )}
                    </div>
                  }
                  className="common-flex-center"
                >
                  <img src={Clock} alt="" className="icons" />
                </Tooltip>
                <div>
                  {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY')}
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CATEGORY</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('category_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        // <div className="recipe-builder-col2">
        <div className="subrecipecol2">
          {' '}
          <Tooltip
            title={
              <div>
                <div className="tooltipHead">Category</div>
                <div className="tootipText">{record?.category_name}</div>
              </div>
            }
          >
            {record?.category_name?.substring(0, 22)}
            {record?.category_name?.length > 22 ? <span>...</span> : null}
          </Tooltip>
        </div>
      ),
    },
    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>SUB COUNT</span>

    //       <img
    //         src={UpDownArrow}
    //         className="titleArrow"
    //         onClick={() => {
    //           handleSort('sub_recipe_items', 'number');
    //           setAscend(!ascend);
    //         }}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'sub_recipe',
    //   key: 'sub_recipe',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => (
    //     // <div className="col3">
    //     <div className="">
    //       {Number(record?.sub_recipe_items) === record?.sub_recipe_items &&
    //       record?.sub_recipe_items % 1 !== 0
    //         ? record?.sub_recipe_items?.toFixed(2)
    //         : record?.sub_recipe_items}
    //       {+record?.sub_recipe_items > 1 ? 'items' : 'item'}
    //     </div>
    //   ),
    // },
    {
      title: () => (
        <div className="tableTitle">
          <span>APPROVAL STATUS</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('servings', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'servings',
      key: 'servings',
      responsive: ['md'],
      render: (data: any, record: any) => (
        // <div className="col3">{record?.servings + ' items'}</div>
        // <div>
        //   {record?.status == 0 ? (
        //     <img className="rd-approveIcon" src={pending} />
        //   ) : record?.status == 1 ? (
        //     <img className="rd-rejectIcon" src={rejected} />
        //   ) : (
        //     <img className="rd-approveIcon" src={approved} />
        //   )}
        // </div>
        <div className="rd-recipe-status-align-center">
          <div
            className={`requestStatusContainer ${
              record?.status_type?.toLowerCase() == 'pending'
                ? 'inventoryTransfer-request-pending'
                : record?.status_type?.toLowerCase() == 'approved'
                ? 'inventoryTransfer-request-approve'
                : 'inventoryTransfer-request-reject'
            } `}
          >
            {record?.status_type}
          </div>
        </div>
      ),
    },

    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>CREATED</span>

    //       <img
    //         src={UpDownArrow}
    //         className="titleArrow"
    //         onClick={() => {
    //           handleSort('created_at', 'string');
    //           setAscend(!ascend);
    //         }}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'created_at',
    //   key: 'created_at',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => (
    //     <div className="">
    //       {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY HH:mm:ss')}
    //     </div>
    //   ),
    // },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          menu={{
            items:
              permissionStatus && permissionStatus === 2
                ? record?.status_type === 'Pending'
                  ? items
                  : itemsForReadOnlyUsers
                : itemsForReadOnlyUsers,
            onClick: handleDropDownClick,
          }}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a onClick={() => setSelectedMenu(record)}>
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    if (currentBrandId !== currentBrand?.brand_id) {
      setKeyword('');
      setAllowRequest(true);
    }
  }, [currentBrand, currentShop]);

  useEffect(() => {
    if (allowRequest) {
      pageData();
      setAllowRequest(false);
    }
  }, [allowRequest]);

  useEffect(() => {
    checkAuthentication(user, navigate);
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'R&D Recipe'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, currentBrand]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const handleingredient = async (data: any) => {
    const details = await RecipeActions.getDetailRecipe(data?.rb_id);
    setDialogData(details);
    setOpenDialog(true);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    if (option == 1) {
      const { data } = await RecipeActions.getApprovalById(selectedRecipeId);
      setData(data);
      if (data) {
        setDialog(true);
      }
    }

    if (option == 2) {
      setLoader(true);
      const { data, success } = await RecipeActions.getRecipeLink(
        selectedRecipeId
      );
      setLoader(false);
      if (success) {
        // setPdf(data);
        openInNewTab(data);
      }
    }
    switch (option) {
      case 3: {
        const recipe_type = selectedRecipe?.recipe_type;
        navigate('wizard', {
          state: {
            update: true,
            id: selectedRecipeId,
            guest_recipe_limit: 0,
            type: recipe_type === 'Sub Recipe' ? 2 : 1,
          },
        });
        break;
      }
      case 4:
        setOpenDeleteDialog(true);
        break;
      default:
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteContinue = async () => {
    setOpenDeleteDialog(false);
    selectedRecipeId.length &&
      (await RecipeActions.delRecipe(selectedRecipeId));
    pageData();
  };

  const DropDownmenuProps = {
    items: itemsForReadOnlyUsers,
    onClick: handleDropDownClick,
  };

  const DropDownMenuWithoutApprovalProps = {
    items:
      permissionStatus && permissionStatus === 2
        ? items
        : itemsForReadOnlyUsers,
    onClick: handleDropDownClick,
  };

  const pageData = async () => {
    const brand = await Utils.getCurrentBrand();
    setRecipeLoader(true);
    const recipeData = await RecipeActions.getRecipes(
      dataLength,
      current,
      currentBrand?.brand_id || brand?.brand_id,
      1,
      keyword
    );

    if (recipeData == undefined || !recipeData?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(recipeData?.data?.recipe_count);
      setIngredientCount(recipeData?.data?.ingredients_count);
      setTotal(recipeData?.data?.pagination?.total);
      setRecipes(recipeData?.data?.items);
      setSortData(recipeData?.data?.items);
    }
    setRecipeLoader(false);
  };

  const pageDataFilter = async (filters: any) => {
    setRecipeLoader(true);
    const brand = await Utils.getCurrentBrand();

    const recipeData = await RecipeActions.recipesFiltersForRD(
      dataLength,
      current,
      filters,
      currentBrand?.brand_id || brand?.brand_id,
      1,
      search
    );

    if (recipeData == undefined || !recipeData?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(recipeData?.data?.recipe_count);
      setIngredientCount(recipeData?.data?.ingredients_count);
      setTotal(recipeData?.data?.pagination?.total);
      setSortData(recipeData?.data?.items);
    }

    setRecipeLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setRecipeLoader(true);
    setSearch(keyword);
    // if (keyword) {
    //   const results = sortData.filter(
    //     (obj: any) =>
    //       obj.recipe_name.toLowerCase().includes(keyword.toLowerCase()) ||
    //       obj.category_name.toLowerCase().includes(keyword.toLowerCase()) ||
    //       obj.sub_recipe_items.toString()?.includes(keyword.toString()) ||
    //       obj.status_type?.toLowerCase()?.includes(keyword.toLowerCase()) ||
    //       obj.created_at?.includes(keyword)
    //   );
    //   setSortData(results);
    // } else {
    //   setSortData(recipes);
    // }

    const searchResultofRecipes = await RecipeActions.searchRecipe(
      dataLength,
      current,
      keyword
    );
    if (searchResultofRecipes == undefined || !searchResultofRecipes?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(searchResultofRecipes?.data?.recipe_count);
      setIngredientCount(searchResultofRecipes?.data?.ingredients_count);
      setTotal(searchResultofRecipes?.data?.pagination?.total);
      setSortData(searchResultofRecipes?.data?.items);
    }

    setRecipeLoader(false);
  };

  const handleCSV = async (type = 'Recipes') => {
    const response = await Attributes.exportRBCSV(type);
    const { data } = response;
    window.open(data?.url);
  };

  const handleMarkRecipe = async (payload: any, recipe_id: any) => {
    setErrorList({});
    setErrorStatus(false);
    setLoader(true);
    const response = await RecipeActions.markRecipe(payload, recipe_id);
    setLoader(false);
    if (response?.success) {
      setDialog(false);
      pageData();
    } else {
      setErrorList(response?.error);
      setErrorStatus(true);
    }
  };

  const handleCookBook = async () => {
    setLoader(true);
    const response = await RecipeApiActions.exportCookBook('recipe_builder');
    const { success, data } = response;
    if (success) {
      openInNewTab(data);
    }
    setLoader(false);
  };

  const onPressInterest = async () => {
    const response = await AuthActions.UserSignUp({
      email: Utils.getCurrentUser()?.email,
      phone: '',
      full_name: Utils.getCurrentUser()?.name,
      company: Utils.getCurrentCompany()?.company_name,
      comment:
        'This Guest User has reached the allocated limit, kindly connect for Package selection',
      type: 'Get in Touch',
    });

    const { message, success } = response;
    let responseDialogData = {
      OnClick: () => {},
      type: success ? 'success' : 'error',
      messageArray: [message],
      BtnText: 'Ok',
    };
    ResponseDialog(responseDialogData);
  };

  const onCreateNewValidate = async () => {
    const response = await RecipeActions.getGuestCountStatus();
    const { success, data } = response;
    if (success) {
      const { rb_recipe = 0 } = data;
      if (rb_recipe > 5) {
        let responseDialogData = {
          OnClick: onPressInterest,
          type: 'error',
          messageArray: [
            'You have consumed the allocated Recipe limit of 5 as a Guest User. Kindly press the Show Interest button so that our Team will get back to you for available subscription packages. Thank you',
          ],
          BtnText: 'Show Interest',
          showCloseIcon: true,
        };
        ResponseDialog(responseDialogData);
        return false;
      }
      return true;
    }
    return true;
  };

  const onAddNew = async (type: number) => {
    const { signup_source } = user;
    let isValid = true;

    if (signup_source !== 'chefadmin') {
      isValid = await onCreateNewValidate();
    }

    isValid &&
      navigate('wizard', {
        state: {
          update: false,
          id: '',
          guest_recipe_limit: signup_source !== 'chefadmin' ? total : 0,
          type,
        },
      });
  };

  {
    /* <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={onAddNew}
      shape="round"
      size="middle"
  /> */
  }

  const actionButton = (
    <Dropdown
      menu={{ items: itemsForRD, onClick: handleAddNewType }}
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
      trigger={['click']}
    >
      <Button
        className="theme-button recipeBtn"
        icon={<PlusOutlined />}
        shape="round"
        size="middle"
      >
        Add New
      </Button>
    </Dropdown>
  );

  return (
    <div className="recipeBuilderPage">
      <Dialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleSubDialog={(id: any) => {
          setOpenDialog(false);
          setOpenSubDialog(true);
          setId(id);
        }}
        printApi={RecipeActions}
        data={dialogData}
        component={'RecipeBuilder'}
      />
      <SubDialog
        open={openSubDialog}
        data={id}
        handleSubDialog={() => {
          setOpenSubDialog(false);
          setOpenDialog(true);
        }}
        component={'RecipeBuilder'}
        handleCloseAll={() => {
          setOpenSubDialog(false);
          setOpenDialog(false);
        }}
      />
      {dialog && (
        <IngendientListDialog
          open={dialog}
          handleClose={handleClose}
          data={data}
          handleMarkRecipe={handleMarkRecipe}
          loader={loader}
          errorList={errorList}
          errorStatus={errorStatus}
          component={'r&d_recipe'}
        />
      )}
      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={`Are you sure want to delete ${dialogData?.recipe_name}?`}
      />

      <Breadcrumb
        handleCSV={handleCSV}
        heading="R&D Recipe List"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <Spin spinning={loader} size="large">
        <Page title="Dashboard">
          <Row className="listingTopFilter">
            <Col md={24} lg={4}>
              <ListCount
                count1={recipeCount}
                title1="Total Recipes"
                count2={ingrdientCount}
                title2="Total Ingredients"
              />
            </Col>
            <Col md={24} lg={20}>
              <div className="search-wrapper">
                <SearchBox
                  keyword={keyword}
                  setKeyword={setKeyword}
                  action={onSearch}
                  placeholderText="Search..."
                  refresh={pageData}
                />
                <div
                  className="exportButtonForCookBook"
                  onClick={handleCookBook}
                >
                  <img src={Export} alt="" />
                  <div className="exportText"> Export Cook Book</div>
                </div>
                <Dropdown
                  menu={{ items: itemsForRDExport, onClick: handleExportCSV }}
                  placement="bottomRight"
                  arrow={{ pointAtCenter: true }}
                  trigger={['click']}
                >
                  <div
                    className="exportButton"
                    // onClick={handleCSV}
                  >
                    <img src={Export} alt="" />
                    <div className="exportText"> Export CSV</div>
                  </div>
                </Dropdown>
                <IconSwitcher listViewLayout={design} setListView={setDesign} />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={8}>
              <Filter
                action={pageDataFilter}
                apiCall={RecipeActions.getRecipesFilters}
              />
            </Col>
            <Col lg={18} md={16}>
              {!design && !recipeLoader ? (
                <GridView
                  handledata={''}
                  DropDownmenuProps={DropDownmenuProps}
                  DropDownMenuWithoutApprovalProps={
                    DropDownMenuWithoutApprovalProps
                  }
                  setSelectedMenu={setSelectedMenu}
                  dataSource={sortData || []}
                  component={'recipeBuilder'}
                  handleDialog={''}
                  handleOpen={(data: any) => {
                    handleingredient(data);
                  }}
                  UTCtoLocal={UTCtoLocal}
                />
              ) : (
                !design && (
                  <div className="loader-wrapper">
                    <Spin spinning={recipeLoader} className="recipeLoader" />
                  </div>
                )
              )}

              {design && !recipeLoader ? (
                <ListView
                  limit={dataLength}
                  items={items}
                  dataSource={[...sortData] || []}
                  columns={columns}
                  component={'recipe builder'}
                />
              ) : (
                design && (
                  <div className="loader-wrapper">
                    <Spin spinning={recipeLoader} className="recipeLoader" />
                  </div>
                )
              )}
              <Pagination
                total={total}
                menuProps={menuProps}
                onChange={onChange}
                current={current}
                dataLength={dataLength >= total ? total : dataLength}
                handleLength={(data: number) => setDataLength(data)}
              />
            </Col>
          </Row>
        </Page>
      </Spin>
    </div>
  );
};

export default Dashboard;
