/* eslint-disable no-unused-vars */
import {
  Navigate,
  useNavigate,
  Route,
  Routes,
  redirect,
} from 'react-router-dom';
import AdminLayout from '../layout/adminLayout';
import RecipeBuilder from '../pages/recipeBuilder';
import RDSubRecipe from '../pages/rdSubRecipeList';
import RDSubRecipeWizard from '../pages/rdSubRecipeWizard';
import Recipe from '../pages/recipe';
import Wizard from '../pages/Wizard';
import SubRecipe from '../pages/subRecipe';
import RecipeWizard from '../pages/recipeWizard';
import SubRecipeWizard from '../pages/subRecipeWizard';
import ProductionPlan from '../pages/productionPlan';
import ProductionRecipeWizard from '../pages/productionRecipeWizard';
import Brands from '../pages/brands';
import BrandWizard from '../pages/BrandWizard';
import Branch from '../pages/branches';
import BranchWizard from '../pages/branchWizard';
import BranchCopyWizard from '../pages/branchCopy';
import Company from '../pages/company';
import Menu from '../pages/menu';
import Combos from '../pages/combos';

import Variation from '../pages/variation';
import VariationWizard from '../pages/variationWizard';
import Sizes from '../pages/sizes';
import SizesWizard from '../pages/sizesWizard';
import CookingMethods from '../pages/cookingMethods';
import CookingMethodsWizard from '../pages/CookingMethodsWizard';
import OrderTypes from '../pages/orderTypes';
import OrderTypesWizard from '../pages/orderTypesWizard';
import ManageMenu from '../pages/manageMenu';
import ComboWizard from '../pages/comboWizard';
import Modifiers from '../pages/modifier';
import ModifierWizard from '../pages/modifierWizard';
import CompanyWizard from '../pages/CompanyWizard';
import CompanyCopyWizard from '../pages/companyCopyWizard';

import UploadRecipe from '../pages/uploadRecipe';
import UserListView from '../pages/userList';
import UserWizard from '../pages/userListWizard';
import UploadImages from '../pages/uploadimages';
import UploadSubRecipeWizard from '../pages/uploadSubRecipeWizard';
import Utils from '../redux/utils';
import Dashboard from '../pages/dashboard';
import UploadSubRecipe from '../pages/uploadSubRecipe';
import UploadImageWizard from '../pages/uploadimageswizard';
import UploadMarketList from '../pages/uploadMarketList';
import MenuCategory from '../pages/menuCategory';
import MenuCategoryWizard from '../pages/menuCategoryWizard';
import UploadImagesForRB from '../pages/uploadImagesForRB';
import UploadImageWizardForRB from '../pages/uploadimageswizardforRB';
import UploadSubRecipeForRB from '../pages/uploadSubRecipeForRB';
import UploadSubRecipeWizardForRB from '../pages/uploadSubRecipeWizardForRB';
import UploadMarketListForRB from '../pages/uploadMarketListForRB';
import InventoryListPage from '../pages/marketlist';
import UploadIngredients from '../pages/bulkUploadIngredients';
import BulkUploadRDSubRecipe from '../pages/bulkUploadR&DSubRecipe';
import BulkUploadRDRecipe from '../pages/bulkUploadR&DRecipe';
import Permission from '../pages/permission';
import Feedback from '../pages/feedback';
import { ComingSoon } from '../components';
// import { Loader } from '../components';
import LeadGeneration from '../pages/leadGeneration';
import Approval from '../pages/approval';
import ApprovalWizard from '../pages/approvalWizard';
import UserAccess from '../pages/userAccess';
import Supply from '../pages/supply';
import SupplyWizard from '../pages/supplyWizard';
import IngredientList from '../pages/Ingredient List';
import BuyingUnit from '../pages/buyingUnit';
import CashPurchase from '../pages/cashPurchase';
import CashPurchaseWizard from '../pages/cashPurchaseWizard';
import PurchaseOrder from '../pages/purchaseOrder';
import PurchaseOrderWizard from '../pages/purchaseOrderWizard';
import BuyingUnitWizard from '../pages/buyingUnitWizard';
import RecipeBuilderMarketList from '../pages/RecipeBuilderMarketList';
import BulkUploadSupplyIngredient from '../pages/bulkUploadSupplyIngredient';
import SupplierIngredients from '../pages/SupplierIngredients';
import Requisitions from '../pages/requisition';
import RequisitionWizard from '../pages/requisitionWizard';
import InventoryTransfer from '../pages/inventoryTransfer';
import StockCount from '../pages/CountStock';
import ProductionPlanWizard from '../pages/ProductionPlanWizard';
import Wastage from '../pages/wastage';
import WastageWizard from '../pages/wastageWizard';
import SustainabilityWizard from '../pages/sustainabilityWizard';
import CashPurchaseWizardDemo from '../pages/cashPurchaseWizardDemo';
import Reports from '../pages/Reports';
import UserPersonalInfo from '../pages/UserPersonalInfo';
import StockTaking from '../pages/stockTaking';
import Subscription from '../pages/subscription';
import SubscriptionWizard from '../pages/subscriptionWizard';
import SubscriptionCheck from '../pages/subscriptionCheck';
import SignUps from '../pages/signUps';
import Integration from '../pages/integration';
import SubscriptionApi from '../redux/middleware/subscription';
export const AppRoutes = () => {
  const user = Utils.getCurrentUser();
  const menu = Utils.getCurrentMenu();
  const navigate = useNavigate();

  const getSubscriptionInfo = async () => {
    const response = await SubscriptionApi.getSubscriptionById(user?.id);
    if (response?.success) {
      const { data } = response;
      Utils.setUserSubscriptions(data);
    }
  };

  let path;
  if (user?.roles[0].id === 2) {
    const subscriptionCheckUrls = [
      'http://localhost:3000/app/subscription_check',
      'https://portal.sadaynaal.com/app/subscription_check',
      'https://https://kms.chefadmin.com/app/subscription_check',
    ];

    const isSubscriptionCheckPage = subscriptionCheckUrls.includes(
      window.location.href
    );
    if (
      user?.subscription?.access_status === true &&
      user?.subscription?.billing_url
    ) {
      if (
        document.referrer === user?.subscription?.billing_url ||
        isSubscriptionCheckPage
      ) {
        getSubscriptionInfo();
        if (user?.subscription?.status === 'past_due') {
          path = menu[2]?.path || menu[2]?.subs[0].path;
        } else if (user?.subscription?.status === 'canceled') {
          path = menu[2]?.path || menu[2]?.subs[0].path;
        } else if (
          user?.subscription?.status === 'active' ||
          user?.subscription?.status === 'trialing' ||
          user?.subscription?.status === 'trailing'
        ) {
          path = menu[2]?.path || menu[2]?.subs[0].path;
        } else {
          path = menu[2]?.path || menu[2]?.subs[0].path;
        }
      } else {
        path = menu[2]?.path || menu[2]?.subs[0].path;
      }
    } else if (
      user?.subscription?.access_status === false &&
      user?.subscription?.billing_url
    ) {
      window.location.href = user?.subscription?.billing_url;
    } else if (
      user?.subscription?.access_status === true &&
      !user?.subscription?.billing_url
    ) {
      path = menu[2]?.path || menu[2]?.subs[0].path;
    } else if (
      user?.subscription?.status === 'active' ||
      user?.subscription?.status === 'trialing' ||
      user?.subscription?.status === 'trailing'
    ) {
      path = menu[2]?.path || menu[2]?.subs[0].path;
    } else {
      if (user?.company_profile_status === 0) {
        path = menu[2]?.path || menu[2]?.subs[0].path;
      }
    }
  } else if (user?.roles[0].id === 1) {
    path = menu[2]?.path || menu[2]?.subs[0].path;
  } else if (user?.roles[0].id === 4) {
    path = menu[1]?.path || menu[1]?.subs[0].path || menu[0]?.subs[0].path;
  } else if (user?.roles[0].id === 5) {
    path = menu[1]?.path || menu[1]?.subs[0]?.path;
  } else if (user?.roles[0].id === 3) {
    path = menu[1]?.path || menu[1]?.subs[0]?.path;
  } else {
    path =
      (menu?.length && (menu[0]?.path || menu[0]?.subs[0]?.path)) ||
      'r&d-recipe';
  }

  return (
    <AdminLayout containerClassName="">
      <Routes>
        <Route path="" element={<Navigate to={`/${path}`} replace />} />
        <Route path="/userPersonalInfo" element={<UserPersonalInfo />} />
        <Route path="/r&d-recipe" element={<RecipeBuilder />} />
        <Route path="/r&d-subrecipe" element={<RDSubRecipe />} />
        <Route path="/research" element={<RecipeBuilder />} />
        <Route path="/recipes" element={<Recipe />} />
        <Route path="/r&d-recipe/wizard" element={<Wizard />} />
        <Route path="/recipes/wizard" element={<RecipeWizard />} />
        <Route path="/subrecipe" element={<SubRecipe />} />
        {/* <Route path="/subrecipe/wizard" element={<SubRecipeWizard />} /> */}
        <Route path="/subrecipe/wizard" element={<SustainabilityWizard />} />
        <Route path="/sustainability" element={<SustainabilityWizard />} />
        <Route path="/productionPlan" element={<ProductionPlan />} />
        {/* <Route path="/productionInventory" element={<Loader />} />
        <Route path="/productionConsumption" element={<Loader />} />  */}
        <Route path="/brands" element={<Brands />} />
        <Route path="/brands/wizard" element={<BrandWizard />} />
        <Route path="/branchCopy" element={<BranchCopyWizard />} />
        <Route path="/branches" element={<Branch />} />
        <Route path="/branches/wizard" element={<BranchWizard />} />
        <Route path="/company" element={<Company />} />
        <Route path="/company/wizard" element={<CompanyWizard />} />
        <Route path="/subscriptions" element={<Subscription />} />
        <Route path="/subscriptions/wizard" element={<SubscriptionWizard />} />
        <Route path="/signups" element={<SignUps />} />
        <Route
          path="/company/companyCopyWizard"
          element={<CompanyCopyWizard />}
        />
        <Route path="/countStock" element={<StockCount />} />
        <Route path="/stockTaking" element={<StockTaking />} />

        <Route path="/combos" element={<Combos />} />
        <Route path="/combos/wizard" element={<ComboWizard />} />
        <Route path="/modifiers" element={<Modifiers />} />
        <Route path="/modifiers/wizard" element={<ModifierWizard />} />
        <Route path="/bulkUpload" element={<UploadRecipe />} />

        <Route path="/uploadSubRecipes" element={<UploadSubRecipe />} />
        <Route
          path="/uploadSubRecipes/wizard"
          element={<UploadSubRecipeWizard />}
        />
        <Route
          path="/uploadSubRecipesForRB"
          element={<UploadSubRecipeForRB />}
        />
        <Route
          path="/uploadSubRecipesForRB/wizard"
          element={<UploadSubRecipeWizardForRB />}
        />
        <Route path="/uploadMarketList" element={<UploadMarketList />} />
        <Route
          path="/R&DuploadMarketList"
          element={<UploadMarketListForRB />}
        />
        <Route path="/users" element={<UserListView />} />
        <Route path="/users/wizard" element={<UserWizard />} />
        <Route
          path="/productionPlan/wizard"
          element={<ProductionPlanWizard />}
        />
        <Route path="/menu" element={<Menu />} />
        <Route path="/variations" element={<Variation />} />
        <Route path="/variations/wizard" element={<VariationWizard />} />
        <Route path="/sizes" element={<Sizes />} />
        <Route path="/sizes/wizard" element={<SizesWizard />} />
        <Route path="/cookingMethods" element={<CookingMethods />} />
        <Route
          path="/cookingMethods/wizard"
          element={<CookingMethodsWizard />}
        />
        <Route path="/orderTypes" element={<OrderTypes />} />
        <Route path="/orderTypes/wizard" element={<OrderTypesWizard />} />
        <Route path="/manageMenu" element={<ManageMenu />} />
        <Route path="/uploadimage" element={<UploadImages />} />
        <Route path="/uploadimage/wizard" element={<UploadImageWizard />} />
        <Route path="/uploadimageForRB" element={<UploadImagesForRB />} />
        <Route
          path="/uploadimageForRB/wizard"
          element={<UploadImageWizardForRB />}
        />
        <Route path="/menuCategory" element={<MenuCategory />} />
        <Route path="/menuCategory/wizard" element={<MenuCategoryWizard />} />
        {/* <Route
          path="/menuCategory/subRecipeCategoryWizard"
          element={<SubRecipeCategoryWizard />}
        /> 
        <Route
          path="/menuCategory/productionCategoryWizard"
          element={<ProductionCategoryWizard />}
        />

        <Route path="/ingredientCategory" element={<IngredientCategory />} />
        <Route
          path="/ingredientCategory/wizard"
          element={<IngredientCategoryWizard />}
        />
        <Route path="/rd-menuCategory" element={<MenuCategoryBuilder />} />
        <Route
          path="/rd-menuCategory/wizard"
          element={<MenuCategoryBuilderWizard />}
        />
        <Route
          path="/rd-ingredientCategory"
          element={<IngredientCategoryBuilder />}
        />
        <Route
          path="/rd-ingredientCategory/wizard"
          element={<IngredientCategoryBuilderWizard />}
        /> */}
        <Route path="/inventory" element={<InventoryListPage />} />
        <Route path="/cashPurchase" element={<CashPurchase />} />
        <Route path="/cashPurchase/Wizard" element={<CashPurchaseWizard />} />
        <Route
          path="/cashPurchaseDemo/Wizard"
          element={<CashPurchaseWizardDemo />}
        />
        <Route path="/purchaseOrder" element={<PurchaseOrder />} />
        <Route path="/purchaseOrder/Wizard" element={<PurchaseOrderWizard />} />

        <Route path="/screens" element={<Permission />} />
        <Route path="/uploadIngredients" element={<UploadIngredients />} />
        <Route
          path="/bulkUploadR&DSubRecipe"
          element={<BulkUploadRDSubRecipe />}
        />
        <Route path="/bulkUploadR&DRecipe" element={<BulkUploadRDRecipe />} />
        {/* <Route path="/notfound" element={<ComingSoon />} /> */}
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/leadGeneration" element={<LeadGeneration />} />
        <Route path="/approval" element={<Approval />} />
        <Route path="/approval/wizard" element={<ApprovalWizard />} />
        <Route path="/supply" element={<Supply />} />
        <Route path="/supply/wizard" element={<SupplyWizard />} />
        <Route path="/userAccess" element={<UserAccess />} />
        <Route path="/ingredientlist" element={<IngredientList />} />
        <Route path="/ingredientlist/wizard" element={<Wizard />} />
        <Route path="/R&DmarketList" element={<RecipeBuilderMarketList />} />
        <Route path="/buyingUnit" element={<BuyingUnit />} />
        <Route path="/buyingUnit/wizard" element={<BuyingUnitWizard />} />
        <Route path="/orderStock" element={<Requisitions />} />
        <Route path="/orderStock/wizard" element={<RequisitionWizard />} />
        <Route path="/inventorytransfer" element={<InventoryTransfer />} />

        <Route path="/wastage" element={<Wastage />} />
        <Route path="/wastage/wizard" element={<WastageWizard />} />

        <Route
          path="/bulkUploadSupplyIngredients"
          element={<BulkUploadSupplyIngredient />}
        />
        <Route path="/UntaggedIngredients" element={<SupplierIngredients />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/dashboard" element={<Dashboard />} />

        <Route path="/integration" element={<Integration />} />
        {/* <Route path="/reports" element={<ComingSoon />} />
        <Route path="/dashboard" element={<ComingSoon />} /> */}
        <Route path="*" element={<ComingSoon />} />
        <Route
          path="/review-user-package"
          element={<Navigate to={'/auth'} replace />}
        />
        <Route path="/subscription-check" element={<SubscriptionCheck />} />
      </Routes>
    </AdminLayout>
  );
};
