/* eslint-disable no-unused-vars */
import { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../assets/svg/logo.svg';
import arrowDown from '../../assets/images/ArrowDownBlack.png';
import arrowLeft from '../../assets/images/ArrowLeftBlack.png';
import './style.scss';
import Utils from '../../redux/utils';
import { useTranslation } from 'react-i18next';
const SideBar = () => {
  const [active, setActive] = useState<null | number>(null);
  const [activeItem, setActiveItem] = useState('');
  const handleToggle = (index: any) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const userPermissions = Utils.getCurrentMenu();
  const betaItems = userPermissions?.filter(
    (menuItem: any) =>
      menuItem.routeName === 'Engineering' ||
      menuItem.routeName === 'Production'
  );
  const otherItems = userPermissions?.filter(
    (menuItem: any) =>
      menuItem.routeName !== 'Engineering' &&
      menuItem.routeName !== 'Production'
  );

  return (
    <>
      <header className="page-header">
        <nav>
          <div className="card">
            <div className="card-body">
              <div className="app-logo">
                <img src={Logo} alt="React Logo" />
              </div>
              {otherItems?.map((menuItem: any, index: number) => {
                return (
                  <AccordionItem
                    key={index}
                    active={active}
                    handleToggle={handleToggle}
                    setActiveItem={setActiveItem}
                    activeItem={activeItem}
                    menuItem={menuItem}
                  />
                );
              })}
              {betaItems.length > 0 && (
                <div className="beta-section">
                  <h3>BETA</h3>
                  {betaItems?.map((menuItem: any, index: number) => {
                    return (
                      <AccordionItem
                        key={index}
                        active={active}
                        handleToggle={handleToggle}
                        setActiveItem={setActiveItem}
                        activeItem={activeItem}
                        menuItem={menuItem}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </nav>
        {/*<div
          className="header-bottom carret-button-bottom"
          onClick={() => setbottomSheetOpen((prevState) => !prevState)}
        >
          <img src="./asset/User.png" className="header-bottom-user-image" />
          <div className="bottom-user-detail-container">
            <h6>Saira Fasya</h6>
            <h6>Brand Admin</h6>
          </div>
          <div>
            {bottomSheetOpen ? (
              <img src="asset/svg/carret-up.svg" alt="arrow-up" />
            ) : (
              <img src="asset/svg/carret-down.svg" alt="arrow-down" />
            )}
          </div>
            </div> */}
      </header>
    </>
  );
};

const AccordionItem = (props: any) => {
  const { i18n } = useTranslation();
  const contentEl = useRef<any>();
  const { handleToggle, active, menuItem, activeItem, setActiveItem } = props;
  // eslint-disable-next-line no-unused-vars
  const { routeName, id, hasChild, subs, icon, routeNameAr } = menuItem;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentActivePath = location.pathname.split('/');
    setActiveItem(currentActivePath[currentActivePath.length - 1]);
  }, []);

  const getIcon = (item: any) => {
    return item.icon ? item.icon : icon;
  };

  const onPressMenuItem = (item: any) => {
    setActiveItem(item.routeName);
    navigate(`/${item.path}`);
  };

  const onSelectMenu = (id: any, child: any) => {
    handleToggle(id);
    hasChild ? onPressMenuItem(child && child[0]) : onPressMenuItem(child);
    // onPressMenItem(child && child[0]);
  };

  const iconWidth = icon.includes('static/media/Inventory') ? 15 : 20;

  return (
    <div className="rc-accordion-card">
      <div className="rc-accordion-header">
        <div
          className={`rc-accordion-toggle p-3 ${active === id ? 'active' : ''}`}
          // onClick={() => hasChild && onSelectMenu(id, subs)}
          onClick={() =>
            hasChild ? onSelectMenu(id, subs) : onSelectMenu(id, menuItem)
          }
        >
          <div>
            {/* <img src={icon} alt={routeName} width={iconWidth} /> */}
            <h5 className="rc-accordion-title table-title1-hover">
              {i18n.language === 'en' ? routeName : routeNameAr}
            </h5>
          </div>
          <img
            src={hasChild ? (active === id ? arrowDown : arrowLeft) : ''}
            alt={hasChild ? 'Expand Icon' : ''}
            className="expand-icon sidebar-icon"
          />
          {/* {hasChild && <i className="fa fa-chevron-down rc-accordion-icon"></i>} */}
        </div>
      </div>
      <div
        ref={contentEl}
        className={`rc-collapse ${active === id ? 'show' : ''}`}
        style={
          active === id
            ? { height: contentEl.current.scrollHeight }
            : { height: '0px' }
        }
      >
        {hasChild &&
          subs.map((item: any) => (
            <div className="rc-accordion-body" key={item?.id}>
              <img src={getIcon(item)} alt={routeName} width={20} />
              <h6
                className={`rc-accordion-subtitle ${
                  activeItem === item.routeName
                    ? 'activeMenuItem'
                    : 'table-title1-hover'
                }`}
                onClick={() => onPressMenuItem(item)}
              >
                {i18n.language === 'en' ? item.routeName : item.routeNameAr}
              </h6>
            </div>
          ))}
      </div>
    </div>
  );
};
export default SideBar;
