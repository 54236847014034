/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Table, Tooltip } from 'antd';
import ArrowDown from '../../assets/images/ArrowDown.png';
import ArrowRight from '../../assets/images/ArrowRight.png';
import SubMenu from '../../assets/images/SubMenu.svg';
import './style.scss';

type Props = {
  dataSource: any;
  columns: any;
  items: any;
  component?: any;
  handleOpen?: any;
  limit: any;
  onChange?: any;
};

type TablePaginationPosition =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight';

function customExpandIcon(prop: any) {
  if (prop?.expanded) {
    return (
      <img
        src={ArrowDown}
        alt=""
        onClick={(e) => {
          prop.onExpand(prop.record, e);
        }}
      />
    );
  } else {
    return (
      <img
        src={ArrowRight}
        alt=""
        onClick={(e) => {
          prop.onExpand(prop.record, e);
        }}
      />
    );
  }
}

const ListView = (props: Props) => {
  let { component, handleOpen, limit, dataSource, columns } = props;
  return (
    <Table
      rowKey={(record: any) => record?.id || record?.recipe_id}
      dataSource={dataSource}
      columns={columns}
      pagination={{
        pageSize: limit,
        position: [
          'none' as TablePaginationPosition,
          'none' as TablePaginationPosition,
        ],
      }}
      expandable={{
        expandedRowRender: (record: any) => (
          <div className="expand-description-container">
            {component == 'menu' && (
              <div>
                <div className="expand-description-title">Description</div>
                <div className="expand-description-text ">
                  {record?.description}
                </div>
              </div>
            )}
            {component != 'menu' && (
              <div>
                <div className="collapseContainer">
                  <span className="collapseHead">Ingredients</span>
                  <span className="collapseHead">QUANTITY</span>
                </div>
                {record?.ingredients?.map((data: any, index: number) => {
                  return (
                    <div
                      className="collapseContainer ExpandedTextAlignment"
                      key={index}
                    >
                      <span className="collapseDesc">
                        {data?.ingredient_name}{' '}
                        {(component == 'recipe builder' ||
                          component == 'subRecipe' ||
                          component == 'production' ||
                          component == 'recipe') &&
                        data?.ingredient_type === 'sub_recipe' ? (
                          <Tooltip
                            title={
                              <div>
                                <div className="tooltipHead">Sub recipe</div>
                                {/* <div className="tootipText">
                                Sub recipe is lorem ipsum dolor
                            </div> */}
                              </div>
                            }
                          >
                            <img
                              src={SubMenu}
                              width={15}
                              alt=""
                              className="icons"
                              onClick={() => {
                                handleOpen(data?.sku);
                              }}
                            />
                          </Tooltip>
                        ) : null}
                      </span>
                      <span className="collapseWeight">
                        {data?.quantity + ' ' + data?.unit}
                      </span>
                    </div>
                  );
                })}
                {/* <div className="collapseContainer line">
                  <span className="collapseDesc">{data?.ingredient_name}</span>
                  <span className="collapseWeight">2g</span>
                </div> */}
                {/* <div className="collapseContainer">
                  <div>
                    <span className="collapseDesc">Bologna, beef</span>
                    <Tooltip
                      title={
                        <div>
                          <div className="tooltipHead">Sub recipe</div>
                          <div className="tooltipText">
                            Sub recipe is lorem ipsum dolor
                          </div>
                        </div>
                      }
                    >
                      <img src={SubMenu} alt="" className="icons" />
                    </Tooltip>
                  </div>
                  <span className="collapseWeight">2g</span>
                </div>
                <div className="collapseContainer">
                  <span className="collapseDesc">Tomatoes, green, raw</span>
                  <span className="collapseWeight">1kg</span>
                </div> */}
              </div>
            )}
          </div>
        ),
        rowExpandable: (record) => record?.category !== 'Not Expandable',
      }}
      expandIcon={(prop) => customExpandIcon(prop)}
    />
  );
};

export default ListView;
