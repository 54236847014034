/* eslint-disable no-unused-vars */
// StripeProvider.tsx
import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SubscriptionApi from '../../../redux/middleware/subscription';

const stripePromise = loadStripe(
  'pk_test_51NV9cNGmIpyYNEMV0IbwVxLvdILMQhCIsU9MwZ3Jv9LhvSesHVewLqG67AD8b1YOBojbQRihPvDmEhEkS5ULtYw300Ru5Ueu5d'
);

// const stripePromise = loadStripe(
//   'pk_live_51MtSMPEEorQv8b5cx4AzvbAYhvIFYICmGJcDzjLnq5iH5nxzFdj1D6mQyR1e6wdhwHCo9PJG85AI5bkY4kwqqwB100mzSEKW8Q'
// );

const StripeProvider: React.FC<
  React.PropsWithChildren<{ selectedPlan: any }>
> = ({ children, selectedPlan }) => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  useEffect(() => {
    if (selectedPlan) getClientIntent(selectedPlan);
  }, [selectedPlan]);

  const getClientIntent = async (plan: any) => {
    const { amount, currency } = plan;
    const params = {
      amount,
      currency,
    };
    const response = await SubscriptionApi.createSubscriptionIntent(params);
    const { data } = response;

    setClientSecret(data?.clientSecret);
  };

  if (!clientSecret) {
    return null;
  }

  const options = {
    clientSecret,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};

export default StripeProvider;
