/* eslint-disable no-unused-vars */
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

interface stackedBarHorizontalProps {
  data: any[];
  source?: string;
  isMulti?: boolean;
  brandcount?: number;
}

const StackedBarHorizontal = (props: stackedBarHorizontalProps) => {
  const { data, source } = props;

  function formatNumberWithEllipsis(number: any) {
    const numberStr = number.toString();
    if (numberStr.length > 5) {
      return numberStr.substring(0, 5) + '...';
    }
    return numberStr;
  }

  const chartWidth = source === 'dayWiseWastage' ? 960 : 460;
  const chartHeight = 240;

  if (!data || data.length === 0) {
    return <div className="no-data-graph">No data to display</div>;
  }

  const daysOfWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  const filledData = daysOfWeek.map((day) => {
    const dayData = data.find((d) => d.name === day) || { name: day };
    return dayData;
  });

  const processedData = filledData.map((dayData) => {
    const sortedData = Object.keys(dayData)
      .filter((key) => key.startsWith('value'))
      .sort((a, b) => dayData[b] - dayData[a]);
    const top5Values = sortedData.slice(0, 5);
    const newData: { [key: string]: any } = { name: dayData.name };
    top5Values.forEach((key) => {
      newData[key] = dayData[key];
    });
    return newData;
  });

  const keys = Object.keys(processedData[0]).filter((key) =>
    key.startsWith('value')
  );
  const bars = keys.map((key, index) => {
    const barColor = ['#d62728', '#a5a5a5', '#f9ab01'][index % 3];
    return <Bar key={key} dataKey={key} stackId="stack" fill={barColor} />;
  });

  return (
    <BarChart
      width={460}
      height={180}
      data={processedData}
      layout="vertical"
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis type="number" />
      <YAxis
        dataKey="name"
        type="category"
        // tickFormatter={formatNumberWithEllipsis}
        tick={{ fontSize: 9 }}
      />
      <Tooltip />
      <Legend />
      {bars}
    </BarChart>
  );
};

export default StackedBarHorizontal;
