import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import { PlusOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  PaginationProps,
  Dropdown,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import type { MenuProps } from 'antd';
import moment from 'moment';
import {
  Page,
  Pagination,
  Breadcrumb,
  Button,
  ListCount,
  SearchBox,
  DeleteConfirmation,
  // PricePlan,
} from '../../components';
import BrandActions from '../../redux/middleware/brands';
import { sortedData, sortNumber } from '../../helper/sortData';
import More from '../../assets/images/More.png';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import Eye from '../../assets/images/Eye.svg';
import EditSmall from '../../assets/images/EditSmall.png';
import BrandList from '../../components/BrandList';
import CompanyLight from '../../assets/images/CompanyLight.png';
import Connect from '../../assets/images/connect.svg';
import Delete from '../../assets/images/Delete.svg';
import DownRed from '../../assets/images/DownRed.png';
import DownGreen from '../../assets/images/DownGreen.png';
import SettingDialog from '../../components/SettingDialog';
import Utils from '../../redux/utils';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './styles.scss';
import { checkAuthentication } from '../../helper/checkAuthentication';
import { foodics_integration_url } from '../../config/constant';

const PageDataLength = [6, 10, 20, 50];

const Brands = () => {
  const user = Utils.getCurrentUser();
  const { t } = useTranslation('translation', {
    keyPrefix: 'brandPage',
  });
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [ascending, setAscending] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [total, setTotal] = useState(0);
  const [Loader, setLoader] = useState(false);
  // const [verify, setVerify] = useState(false);
  const [Count, setCount] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [dialogData, setDialogData] = useState<any>({});
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogLoader, setDialogLoader] = React.useState(false);
  const [info, setInfo] = React.useState('');
  const [search, setSearch] = useState('');
  const { id } = Utils.getUserRole();

  const checkIsIntegrated = (record: any) => {
    const { integrations } = record;
    if (
      integrations &&
      integrations.length > 0 &&
      integrations[0]?.access_token == 1
    ) {
      return true;
    }
    return false;
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <div className="dotOption">{t('view')}</div>,
      icon: <img src={Eye} width={15} className="iconDropdown" />,
    },
    {
      key: '2',
      label: <div className="dotOption">{t('edit')}</div>,
      icon: <img src={EditSmall} className="iconDropdown" />,
    },
    {
      type: 'divider',
    },
    {
      key: '3',
      label: (
        <div className="dotOption">
          {checkIsIntegrated(dialogData) ? 'Integrated' : 'Integrate'}
        </div>
      ),
      icon: <img src={Connect} width={15} className="iconDropdown" />,
      disabled: checkIsIntegrated(dialogData),
    },
    ...(id !== 3
      ? [
          {
            key: '4',
            label: <div className="dotOption deleteText">{t('delete')}</div>,
            icon: <img src={Delete} width={15} className="iconDropdown" />,
          },
        ]
      : []),
  ];

  // eslint-disable-next-line no-unused-vars
  const [selectedId, setSelectedId] = useState('');
  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascending, sortData, column)
        : sortNumber(ascending, sortData, column);

    setSortData(() => arr);
  };

  const { currentCompany } = useSelector((state: any) => state.Auth);

  const items1: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          className="dotOption"
          onClick={async () => {
            await BrandActions.updateStatus(selectedId, 1).then(() =>
              pageData()
            );
          }}
        >
          {t('active')}
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: (
        <div
          className="dotOption deleteText"
          onClick={async () => {
            await BrandActions.updateStatus(selectedId, 0).then(() =>
              pageData()
            );
          }}
        >
          {t('not_active')}{' '}
        </div>
      ),
    },
  ];

  const handleMenuDropdown: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuDropdownProps = {
    items: items1,
    onClick: handleMenuDropdown,
  };

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };

  // React.useEffect(() => {
  //   if (!user?.subscription.length) {
  //     setVerify(true);
  //   }
  // }, []);

  const columns: any = [
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('brand_label')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('company', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'company',
      key: 'company',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col1 font view-align-brand">
          <img src={record?.thumbnail} width="52px" height="52px" />
          <div
            className="subCol1 font sub-view-align-adjustment table-title"
            onClick={() => getBrandInfo(record?.id)}
          >
            {/* <span className="col1Text font">{record?.name}</span> */}
            <Tooltip
              title={
                <div>
                  <div className="tooltipHead">Name</div>
                  <div className="tootipText">{record?.name}</div>
                </div>
              }
            >
              {record?.name?.substring(0, 20)}
              {record?.name?.length > 20 ? <span>...</span> : null}
            </Tooltip>
            <div className="subTextCol1 font">
              <img src={CompanyLight} alt="" className="companyLight" />
              <div className="email">{record?.company}</div>
            </div>
          </div>
        </div>
      ),
    },
    /* {
      title: () => (
        <div className="tableTitle">
          <span>{t('phone')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('phone', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'phone',
      key: 'phone',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="company">{record?.phone}</div>
      ),
    }, */
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('country')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('country', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'country',
      key: 'country',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="country view-align-brand">{record?.country}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('created')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('created_at', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <Tooltip
            title={
              <div>
                <div className="tooltipHead">Created At</div>
                <div className="tootipText">
                  {moment(UTCtoLocal(record?.created_at)).format(
                    'DD-MM-YYYY HH:mm:ss'
                  )}
                </div>
              </div>
            }
          >
            <div className="city">
              {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY')}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('status')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('status', 'number');
              // setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          overlayStyle={{ height: '100px' }}
          menu={menuDropdownProps}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a
            onClick={() => {
              setSelectedId(record?.id);
            }}
          >
            <Space>
              <div
                className={
                  record?.status === 1
                    ? 'statusContainerActive'
                    : 'statusContainerNonActive'
                }
              >
                <div className="status">
                  {record?.status == 1 ? 'Active' : 'Non-active'}
                </div>
                <img src={record?.status === 1 ? DownGreen : DownRed} alt="" />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          overlayStyle={{ height: '100px' }}
          menu={DropDownMenuProps}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a
            onClick={() => {
              setDialogData(record);
              setSelectedId(record?.id);
            }}
          >
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  // useEffect(() => {
  //   if (openDialog) {
  //     getBrandInfo(selectedId);
  //   }
  // }, [openDialog]);

  useEffect(() => {
    checkAuthentication(user, navigate);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, currentCompany]);

  const screenPermission = Utils.getScreenPermission('Brand');
  const validateActionPermission = () => {
    if (screenPermission) {
      // eslint-disable-next-line camelcase
      const { perm_status_id, perm_create } = screenPermission;

      // eslint-disable-next-line camelcase
      if (perm_status_id === 1 || perm_create === 1) {
        return false;
      }
      return true;
    }
    return false;
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteContinue = async () => {
    setOpenDeleteDialog(false);
    await BrandActions.deleteBrands(selectedId).then(() => pageData());
  };

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1:
        getBrandInfo(selectedId);
        break;
      case 2:
        navigate('wizard', { state: { update: true, id: selectedId } });
        break;
      case 3: {
        const { client_id, brand_id } = dialogData;
        const url = `${foodics_integration_url}?client_id=${client_id}&state=${brand_id}`;
        window.location.href = url;
        break;
      }
      case 4:
        setOpenDeleteDialog(true);
        break;
      default:
    }
  };

  const DropDownMenuProps = {
    items: validateActionPermission()
      ? id !== 3
        ? items
        : items.filter((x) => x?.key !== '3')
      : items.filter((x) => x?.key !== '2' && x?.key !== '4'),
    onClick: handleDropDownClick,
  };

  const pageData = async () => {
    setLoader(true);
    const Data = await BrandActions.getBrands(dataLength, current, search);
    if (Data == undefined || !Data?.success) {
      setCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setCount(Data?.data?.brand_count);
      setTotal(Data?.data?.pagination?.total);
      setSortData(Data?.data?.items);
    }
    setLoader(false);
  };

  const actionButton = () => {
    if (id === 3) return null;
    return (
      <Button
        text={t('add_new')}
        className="theme-button recipeBtn"
        icon={<PlusOutlined />}
        handleButtonClick={() => {
          navigate('wizard', { state: { update: false, id: '' } });
        }}
        shape="round"
        size="middle"
      />
    );
  };

  const onSearch = async (keyword: string) => {
    setSearch(keyword);
    setLoader(true);
    const searchResult = await BrandActions.searchBrands(
      dataLength,
      current,
      keyword
    );
    if (searchResult == undefined || !searchResult?.success) {
      setCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setCount(searchResult?.data?.brand_count);
      setTotal(searchResult?.data?.pagination?.total);
      setSortData(searchResult?.data?.items);
    }
    setLoader(false);
  };

  const getBrandInfo = async (id: any) => {
    setDialogLoader(true);
    const response = await BrandActions.getBrandById(id);
    setDialogLoader(false);
    setOpenDialog(true);
    if (response?.success) {
      const { data } = response;
      setInfo(data);
    }
  };

  return (
    <React.Fragment>
      <SettingDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        data={info}
        component={'brand'}
        title={'Brand Detail'}
      />
      {/* {verify ? (
        <PricePlan open={verify} showText={true} handleClose={''} />
      ) : null} */}

      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={
          <>
            Are you sure want to delete {dialogData?.name}?
            {/* <br />
            <i>
              Note: This Delete action will automatically reflect on the
              Integrated platform (e.g. Foodics) as well
            </i> */}
          </>
        }
      />
      <Breadcrumb
        heading={t('brand_name')}
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={validateActionPermission() && actionButton()}
      />
      <Spin spinning={dialogLoader} size="large">
        <Page title="Dashboard">
          <Row className="listingTopFilter">
            <Col lg={16}>
              <ListCount count1={Count} title1={t('total_brands')} />
            </Col>
            <Col lg={8} md={24}>
              <div className="search-wrapper">
                <div className="search-box">
                  <SearchBox
                    action={onSearch}
                    placeholderText={t('search_placeholder')}
                  />
                </div>
              </div>
            </Col>
            {/* <Col lg={3}>
              <Button
              text="Export"
              shape="round"
              handleButtonClick={() => alert('handle Export')}
              className="ExportBtn"
              icon={<FileExcelOutlined />}
            /> 
            </Col> */}
          </Row>

          <Row gutter={12} className="listingTopFilter">
            <Col lg={24} md={24} className="Brands-list-wrapper">
              {!Loader ? (
                <BrandList
                  items={items}
                  dataSource={[...sortData]}
                  columns={columns}
                  limit={dataLength}
                />
              ) : (
                <div className="loader-wrapper">
                  <Spin spinning={Loader} className="Loader" size="large" />
                </div>
              )}
              <Pagination
                total={total}
                menuProps={menuProps}
                onChange={onChange}
                current={current}
                dataLength={dataLength >= total ? total : dataLength}
                handleLength={(data: number) => setDataLength(data)}
              />
            </Col>
          </Row>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default Brands;
