/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Input, Select, Spin } from 'antd';
import { Page } from '../../components';
import './styles.scss';
import Utils from '../../redux/utils';
import { checkAuthentication } from '../../helper/checkAuthentication';
import { useTranslation } from 'react-i18next';
import SubscriptionApi from '../../redux/middleware/subscription';
import moment from 'moment';

const variantList = [
  {
    value: 'Monthly',
    label: 'Monthly',
    key: 1,
  },
  {
    value: 'Yearly',
    label: 'Yearly',
    key: 2,
  },
];

type Props = {
  update: boolean;
  id: number | string;
  bypassPermission?: boolean;
  onSetupSuccess?: any;
};

const Wizard = (props: Props) => {
  const user = Utils.getCurrentUser();
  const { t } = useTranslation('translation', {
    keyPrefix: 'companyPage',
  });
  const navigate = useNavigate();
  const { update, id, bypassPermission, onSetupSuccess } = props;
  const [visible, setVisible] = useState(false);
  const [company, setCompany] = React.useState<any>();
  const [selectedPackage, setPackage] = React.useState<any>();
  const [selectedUser, setUser] = React.useState<any>();
  const [variant, setVariant] = React.useState<any>();
  const [paymentType, setPaymentType] = React.useState<any>();

  const [errorMessage] = useState<any[]>([]);
  const [error, setError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [apiLoader, setApiLoader] = useState(false);

  const [companyList, setCompanyList] = React.useState([]);
  const [packageList, setPackageList] = React.useState<any>([]);
  const [paymentTypeList, setPaymentTypeList] = React.useState<any>([]);
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [current] = useState(1);
  const [dataLength] = useState(5000);

  const getUnregisterCompanies = async () => {
    const response = await SubscriptionApi.getUserSubscriptions(
      dataLength,
      current,
      '',
      0
    );
    if (response?.success) {
      const { data } = response;
      const list =
        data &&
        data.items &&
        data.items.map((item: any) => ({
          value: item?.company_name,
          label: item?.company_name,
          key: item?.company_id,
          item,
        }));
      setCompanyList(list);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const getPackageListing = async () => {
    const response = await SubscriptionApi.getCASubscriptionPackages(
      dataLength,
      current
    );
    if (response?.success) {
      const { data } = response;
      const list =
        data &&
        data.items &&
        data.items.map((item: any) => ({
          value: item?.product_name,
          label: item?.product_name,
          key: item?.id,
          interval: item?.interval,
          trial: item?.free_trial_days,
          item,
        }));
      setPackageList(list);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const getSubscriptionInfo = async () => {
    const response = await SubscriptionApi.getSubscriptionById(id);
    if (response?.success) {
      // eslint-disable-next-line no-unused-vars
      const { data } = response;
    }
  };

  const getPaymentTypeListing = async () => {
    const response = await SubscriptionApi.getSubscriptionPaymentTypes(
      dataLength,
      current
    );
    if (response?.success) {
      const { data } = response;
      const list =
        data &&
        data.items &&
        data.items.slice(1).map((item: any) => ({
          value: item?.type,
          label: item?.type,
          key: item?.id,
        }));
      setPaymentTypeList(list);
    }
  };

  React.useEffect(() => {
    checkAuthentication(user, navigate);
    getUnregisterCompanies();
    getPackageListing();
    getPaymentTypeListing();

    if (update) {
      getSubscriptionInfo();
    }
  }, []);

  React.useEffect(() => {
    if (company) {
      const { item } = company;
      setUser({ user_id: item?.user_id, username: item?.user_name });
    }
  }, [company]);

  const handleBack = () => {
    navigate('/app/subscriptions');
  };

  const handleContinue = async () => {
    setErrorList({});
    setErrorStatus(false);
    if (
      (!bypassPermission && !company) ||
      !selectedPackage ||
      // !variant ||
      !paymentType
    ) {
      setVisible(true);
      return;
    }
    const payload = {
      user_id: bypassPermission
        ? Utils.getCurrentUser()?.id
        : selectedUser?.user_id,
      ca_pkg_id: selectedPackage?.key,
      subscription_payment_type_id: paymentType?.key,
      free_trial: 1, // selectedPackage?.trial,
      starts_at: moment().format('YYYY-MM-DD hh:mm:ss'), // '2023-08-25 13:00:00'
    };
    try {
      setApiLoader(true);
      let response;
      response = await SubscriptionApi.manualSubscription(payload);
      setApiLoader(false);
      if (!response?.success && !bypassPermission) {
        setErrorList(response?.error);
        setErrorStatus(true);
      } else if (!response?.success && bypassPermission) {
        alert(response?.message);
      } else if (bypassPermission) {
        onSetupSuccess('3');
      } else if (!bypassPermission) {
        navigate('/app/subscriptions');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handlePickerChange =
    (fieldName: string) => (value: string | string[], data: any) => {
      if (fieldName === 'company') setCompany(data);
      if (fieldName === 'user') setUser(data);
      if (fieldName === 'package') setPackage(data);
      // if (fieldName === 'variant') {
      //   setVariant(data);
      // }
      if (fieldName === 'payment_type') setPaymentType(data);
    };

  const getVariantPackages = () => {
    // if (variant?.key === 1) {
    return packageList.filter((x: any) => x?.interval == 'month');
    // }
    // return packageList.filter((x: any) => x?.interval == 'year');
  };

  return (
    <React.Fragment>
      <Spin
        size="large"
        className="loadIngredient recipeBuilderloaderHeight"
        spinning={apiLoader}
      >
        <Page
          title="Add New Subscription"
          screenName={bypassPermission ? 'setupProfile' : ''}
        >
          {visible && (
            <Alert
              message="Error"
              description="Please complete all required fields to proceed"
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}
          <div className="alertMessage">
            {error &&
              errorMessage?.map((data: any, index: any) => (
                <Alert
                  message="Error"
                  description={data}
                  type="error"
                  showIcon
                  closable
                  className="wizardAlert"
                  afterClose={() => setError(false)}
                  key={index}
                />
              ))}
          </div>

          <div className="wizardContainer">
            {!bypassPermission && (
              <>
                <div className="wizardDropdown">
                  <div className="wizardLabel">
                    Company <span className="sterik">*</span>
                  </div>
                  <Select
                    size={'large'}
                    placeholder={'Select Company'}
                    onChange={handlePickerChange('company')}
                    style={{ width: 560 }}
                    value={company?.label}
                    options={companyList}
                    className={
                      errorList['country_id']?.length > 0
                        ? 'dropdownUnitColorRed'
                        : ''
                    }
                  />
                  {errorStatus && (
                    <div className="subRecipeErrorMessages">
                      {errorList['country_id']}
                    </div>
                  )}
                </div>
                <div className="wizardDropdown">
                  <div className="wizardLabel">
                    User <span className="sterik">*</span>
                  </div>
                  <Input
                    placeholder={'User'}
                    size="large"
                    value={selectedUser?.username || ''}
                    disabled
                  />
                </div>
              </>
            )}

            {/* <div className="wizardDropdown">
              <div className="wizardLabel">
                Subscription Cycle <span className="sterik">*</span>
              </div>
              <Select
                size={'large'}
                placeholder={'Select Variant'}
                onChange={handlePickerChange('variant')}
                style={{ width: 560 }}
                value={variant?.label}
                options={variantList}
                className={
                  errorList['variant_id']?.length > 0
                    ? 'dropdownUnitColorRed'
                    : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['variant_id']}
                </div>
              )}
            </div> */}
            <div className="wizardDropdown">
              <div className="wizardLabel">
                Package <span className="sterik">*</span>
              </div>
              <Select
                size={'large'}
                placeholder={'Select Package'}
                onChange={handlePickerChange('package')}
                style={{ width: 560 }}
                value={selectedPackage?.label}
                options={getVariantPackages()}
                className={
                  errorList['package_id']?.length > 0
                    ? 'dropdownUnitColorRed'
                    : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['package_id']}
                </div>
              )}
            </div>
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Package Price <span className="sterik">*</span>
              </div>
              <Input
                placeholder={'Package Price'}
                size="large"
                value={
                  selectedPackage
                    ? `${selectedPackage?.item?.amount} ${selectedPackage?.item?.currency}`
                    : ''
                }
                disabled
              />
            </div>
            <div className="wizardDropdown">
              <div className="wizardLabel">
                Payment Method <span className="sterik">*</span>
              </div>
              <Select
                size={'large'}
                placeholder={'Select Payment Method'}
                onChange={handlePickerChange('payment_type')}
                style={{ width: 560 }}
                value={paymentType?.label}
                options={paymentTypeList}
                className={
                  errorList['payment_id']?.length > 0
                    ? 'dropdownUnitColorRed'
                    : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['payment_id']}
                </div>
              )}
            </div>
            {!bypassPermission ? (
              <div className="butonGroup">
                <div className="wizard-button" onClick={() => handleBack()}>
                  {t('back')}{' '}
                </div>
                <div
                  className="continue wizard-button"
                  onClick={() => handleContinue()}
                >
                  {t('continue')}
                </div>
              </div>
            ) : (
              <div className="butonGroup">
                <div
                  className="continue wizard-button"
                  onClick={() => handleContinue()}
                >
                  Save changes
                </div>
              </div>
            )}
          </div>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default Wizard;
